import { useCallback, useMemo } from "react";
import * as R from "ramda";
import { KnowYourCustomerData } from "@blisspointmedia/bpm-types/dist/KnowYourCustomer";
import WidgetContainer from "../Components/WidgetContainer";
import { CBSA_TO_MSA_MAP, CbsaMap } from "../Components/CbsaMap";
import { Brand20, Brand25, Brand30, Brand40, Brand60 } from "../utils/colors";
import { formatDecimal, formatPercent } from "../utils/format-utils";
import {
  CUSTOMER_INSIGHTS_PRETTY_NAMES,
  CustomerInsightsFields,
  TabKey,
} from "./customerInsightsConstants";

interface CustomerInsightsGeoGroupProps {
  data: KnowYourCustomerData[];
  totalPopulation: number;
  header: string;
  tab: TabKey;
}

const CustomerInsightsGeoGroup: React.FC<CustomerInsightsGeoGroupProps> = ({
  data,
  totalPopulation,
  header,
  tab,
}) => {
  const knownPopulation = useMemo(() => {
    const unknownPop = data.find(row => row.levels === "Unknown")?.clientPopulation ?? 0;
    return totalPopulation - unknownPop;
  }, [data, totalPopulation]);

  const getMetricFromData = useCallback(
    (data: KnowYourCustomerData): number => {
      switch (tab) {
        case TabKey.CUSTOMER_BASE:
          return data.clientPopulation / knownPopulation;
        case TabKey.INDEX:
          return data.clientPopulationFraction / data.tuPopulationFraction;
        case TabKey.PENETRATION:
          return data.clientPopulation / data.tuPopulation;
      }
    },
    [tab, knownPopulation]
  );

  const metricMax: number = useMemo(() => Math.max(...data.map(val => getMetricFromData(val))), [
    data,
    getMetricFromData,
  ]);

  const mappedData: Record<string, KnowYourCustomerData> = useMemo(
    () => R.indexBy(R.prop("levels"), data),
    [data]
  );

  const formatAsPercent = tab !== TabKey.INDEX;

  const renderColorGroups: {
    min: number;
    max?: number;
    color: string;
    label: string;
  }[] = useMemo(() => {
    const partialGroups: { min: number; max?: number; color: string }[] = [];

    partialGroups.push({
      min: metricMax * 0.8,
      color: Brand60,
    });
    partialGroups.push({
      min: metricMax * 0.6,
      max: metricMax * 0.8,
      color: Brand40,
    });
    partialGroups.push({
      min: metricMax * 0.4,
      max: metricMax * 0.6,
      color: Brand30,
    });
    partialGroups.push({
      min: metricMax * 0.2,
      max: metricMax * 0.4,
      color: Brand25,
    });
    partialGroups.push({
      min: 0,
      max: metricMax * 0.2,
      color: Brand20,
    });

    const fullGroups = partialGroups.map((group, index) => {
      const { min, max } = group;

      if (!max) {
        return {
          ...group,
          label: `${formatAsPercent ? formatPercent(min, 1) : formatDecimal(min, 2)}+`,
        };
      }

      return {
        ...group,
        label: `
        ${formatAsPercent ? formatPercent(min, 1) : formatDecimal(min, 2)} – ${
          formatAsPercent ? formatPercent(max, 1) : formatDecimal(max, 2)
        }`,
      };
    });

    return fullGroups;
  }, [formatAsPercent, metricMax]);

  const getRenderColor = (cbsa: string): string => {
    const data = getMetricFromData(mappedData[cbsa] ?? mappedData[CBSA_TO_MSA_MAP[cbsa]]);

    for (const group of renderColorGroups) {
      const { color, min, max } = group;
      if (data >= min && (max ? data < max : true)) {
        return color;
      }
    }

    console.error(`No value found for cbsa ${cbsa} / mapped msa ${CBSA_TO_MSA_MAP[cbsa]}`);
    return "transparent";
  };

  const explanationText = useMemo(() => {
    switch (tab) {
      case TabKey.CUSTOMER_BASE:
        return "((Number of customers) /\n(ingested emails)) per MSA";
      case TabKey.INDEX:
        return "((percent of our customers) /\n(percent of total population)) per MSA";
      case TabKey.PENETRATION:
        return "((Number of customers) /\n(total population)) per MSA";
    }
  }, [tab]);

  const legendTitle = useMemo(() => {
    switch (tab) {
      case TabKey.CUSTOMER_BASE:
        return "Customers";
      case TabKey.INDEX:
        return "Index";
      case TabKey.PENETRATION:
        return "Saturation";
    }
  }, [tab]);

  return (
    <WidgetContainer header={header} collapsible>
      <div className="customerInsightsGroup fullWidth">
        <div className="chartContainer geoChartContainer">
          <div className="title">{CUSTOMER_INSIGHTS_PRETTY_NAMES[CustomerInsightsFields.MSA]}</div>
          <div className="geoMap">
            <div className="cbsaMapContainer">
              <CbsaMap
                renderColor={getRenderColor}
                renderTooltip={cbsa => {
                  const data = getMetricFromData(
                    mappedData[cbsa] ?? mappedData[CBSA_TO_MSA_MAP[cbsa]]
                  );
                  return (
                    <div>
                      {cbsa}: {formatAsPercent ? formatPercent(data, 1) : formatDecimal(data, 2)}
                    </div>
                  );
                }}
              />
            </div>
            <div className="legend">
              <div className="legendKey">
                <div className="legendTitle">{legendTitle}</div>
                <div className="explanation">{explanationText}</div>
              </div>
              {renderColorGroups.map(group => (
                <div className="legendItem" key={group.color}>
                  <div className="color" style={{ background: group.color }} />
                  <div className="range">{group.label}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </WidgetContainer>
  );
};

export default CustomerInsightsGeoGroup;
