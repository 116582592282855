import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { NestedOptionConfig, NestedValues } from "./QuestionCard";

interface QuestionCardCheckboxProps {
  questionNumber: string;
  options: string[];
  nestedOptionsConfig?: { [option: string]: NestedOptionConfig[] };
  values?: string[];
  nestedValues?: NestedValues;
  readOnly?: boolean;
  onChange?: (updatedValues: { parentValues: string[]; nestedValues: NestedValues }) => void;
}

const QuestionCardCheckbox: React.FC<QuestionCardCheckboxProps> = ({
  questionNumber,
  options,
  nestedOptionsConfig = {},
  values = [],
  nestedValues = {},
  readOnly = false,
  onChange,
}) => {
  const [parentValues, setParentValues] = useState<string[]>(values);
  const [nestedValuesState, setNestedValues] = useState<NestedValues>(nestedValues);

  const handleParentChange = (label: string) => {
    const updatedValues = parentValues.includes(label)
      ? parentValues.filter(item => item !== label)
      : [...parentValues, label];
    // If parent is unchecked, remove key from nestedValues
    const nested = nestedValuesState;
    if (!updatedValues.includes(label)) {
      delete nested[label];
    }
    setParentValues(updatedValues);
    setNestedValues(nested);
    onChange?.({ parentValues: updatedValues, nestedValues: nested });
  };

  const renderNestedInput = (parentLabel: string, parentChecked: boolean) => {
    const config = nestedOptionsConfig[parentLabel];
    if (!config) {
      return null;
    }

    return (
      <div className="nestedFields">
        {config.map((nestedConfig, index) => {
          const effectiveNestedValues = nestedValuesState[parentLabel] || {};

          if (nestedConfig.inputType === "textarea") {
            const nestedTextValue = effectiveNestedValues[nestedConfig.label || parentLabel] || "";

            return (
              <Form.Group key={index}>
                {nestedConfig.label && <Form.Label>{nestedConfig.label}</Form.Label>}
                <Form.Control
                  type="text"
                  placeholder={nestedConfig.placeholder || "Enter text..."}
                  value={nestedTextValue === true ? "" : nestedTextValue}
                  onChange={e => {
                    const updatedNestedValues = {
                      ...nestedValuesState,
                      [parentLabel]: {
                        ...nestedValuesState[parentLabel],
                        [nestedConfig.label || parentLabel]: e.target.value,
                      },
                    };
                    setNestedValues(updatedNestedValues);
                    onChange?.({ parentValues, nestedValues: updatedNestedValues });
                  }}
                  disabled={readOnly || !parentChecked}
                />
              </Form.Group>
            );
          }

          if (nestedConfig.inputType === "checkbox") {
            return (
              <Form.Group key={index}>
                {nestedConfig.options?.map((option, idx) => {
                  const isChecked = !!effectiveNestedValues[option];

                  return (
                    <Form.Check
                      key={idx}
                      type="checkbox"
                      label={option}
                      checked={isChecked}
                      onChange={() => {
                        const updatedNestedValues = {
                          ...nestedValuesState,
                          [parentLabel]: {
                            ...nestedValuesState[parentLabel],
                            [option]: !isChecked,
                          },
                        };
                        setNestedValues(updatedNestedValues);
                        onChange?.({ parentValues, nestedValues: updatedNestedValues });
                      }}
                      disabled={readOnly || !parentChecked}
                    />
                  );
                })}
              </Form.Group>
            );
          }

          return null;
        })}
      </div>
    );
  };

  return (
    <div className="checkboxInput fields">
      {options.map((option, index) => {
        const isChecked = parentValues.includes(option);

        return (
          <div key={index} className="checkboxOption">
            <Form.Check
              type="checkbox"
              id={`${questionNumber}-${option}-checkbox`}
              label={option}
              checked={isChecked}
              onChange={() => handleParentChange(option)}
              disabled={readOnly}
            />
            {renderNestedInput(option, isChecked)}
          </div>
        );
      })}
    </div>
  );
};

export default QuestionCardCheckbox;
