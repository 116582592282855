import "../Performance/Performance.scss";
import "./SingleChannelPage.scss";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import { computeResolvedDate } from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import { DailyDeliveryData } from "../StreamingDelivery/StreamingDelivery";
import { DateGroupingKey } from "../TVADCrossChannel/homePageConstants";
import { DateRange, StateSetter, typedReactMemo } from "../utils/types";
import { DeliveryMetricOption } from "./Delivery/SingleChannelDeliveryChart";
import { download } from "../utils/download-utils";
import { DownloadToggle } from "../Components/DownloadDropdown/DownloadToggle";
import { FetchFilterBar, useFilterPaneState } from "./FetchFilterBar/FetchFilterBar";
import { FilterPreset } from "@blisspointmedia/bpm-types/dist/pgTables/FilterPreset";
import { makeColumnMetaData } from "../CrossChannel/crossChannelUtils";
import { MdLink, MdViewHeadline } from "react-icons/md";
import { mediaTypesSelector, useCompanyInfo } from "../redux/company";
import { Metric, MetricTotalsByDate } from "@blisspointmedia/bpm-types/dist/CrossChannel";
import { MetricsTableWithPresets as MetricsTable } from "./MetricsTable/MetricsTableWithPresets";
import { Mixpanel, MxE } from "../utils/mixpanelWrapper";
import { Modal, Tooltip } from "react-bootstrap";
import { navigate, RouteComponentProps } from "@reach/router";
import { overrideDateRange } from "../utils/test-account-utils";
import { ReactComponent as ColorScale } from "./colorScale.svg";
import { useMap } from "../utils/hooks/useData";
import { useSelector } from "react-redux";
import { useSetError } from "../redux/modals";
import { validDate } from "../utils/date-utils";
import * as L from "@blisspointmedia/bpm-types/dist/LinearPerformance";
import * as P from "@blisspointmedia/bpm-types/dist/Performance";
import * as R from "ramda";
import * as S from "@blisspointmedia/bpm-types/dist/StreamingPerformance";
import * as uuid from "uuid";
import * as Y from "@blisspointmedia/bpm-types/dist/YoutubePerformance";
import CompareMetrics from "../CrossChannel/Components/CompareMetrics";
import Delivery from "./Delivery/Delivery";
import EditPagePresetModal from "./MetricsTableConfig/EditPagePresetModal";
import EditTablePresetModal from "./MetricsTableConfig/EditTablePresetModal";
import PerformanceGridSkeleton from "../Performance/PerformanceGridSkeleton";
import PresetDropdown from "./PresetDropdown";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import RenameModal from "./RenameModal";
import SparkChart from "../Components/SparkChart/SparkChart";
import useLocation from "../utils/hooks/useLocation";
import {
  CellData,
  ColumnMetaDataMap,
  DimensionMap,
  GlossaryItem,
} from "./MetricsTable/metricsTableUtils";
import {
  Column,
  DeletePresetParams as DeleteTablePresetParams,
  DimensionColumn,
  GetPresetParams as GetTablePresetParams,
  MetricsTablePreset,
  PresetIDGroups,
  SavePresetParams as SaveTablePresetParams,
  SEARCH,
  SEARCHSHOPPING,
  SHOPPING,
  YOUTUBE,
} from "@blisspointmedia/bpm-types/dist/MetricsTable";
import {
  DeletePresetParams as DeletePagePresetParams,
  MetricsPagePreset,
  SavePresetParams as SavePagePresetParams,
} from "@blisspointmedia/bpm-types/dist/MetricsPage";
import {
  ALL_GROUP_NAME,
  decodePrettyUrl,
  encodePrettyUrl,
  getBaseURL,
  getGlobalBrand,
  PerformanceContext,
  SingleChannelMediaTypes,
  STANDARD_GROUP_NAME,
} from "../Performance/performanceUtils";
import {
  BPMButton,
  Button,
  ButtonType,
  DatePicker,
  Dropdown,
  DropdownToggleType,
  FilterBar,
  FullPageSpinner,
  OverlayTrigger,
  Page,
  Spinner,
  useKpiPickerData,
} from "../Components";
import {
  computeCustomRelativeRange,
  computeDateFromComparisonPreset,
  computeDateFromPreset,
} from "@blisspointmedia/bpm-types/dist/RelativeDatePresets";

const enum TabKey {
  DELIVERY = "delivery",
  METRICS_TABLE = "metrics-table",
  OVERVIEW = "overview",
}

const NAVS = [
  { label: "Overview", key: TabKey.OVERVIEW },
  { label: "Metrics Table", key: TabKey.METRICS_TABLE },
  { label: "Delivery", key: TabKey.DELIVERY },
];

export const DATE_GROUPING_MAP: Record<
  "daily" | "weekly" | "monthly" | "quarterly" | "yearly",
  DateGroupingKey
> = {
  daily: "Day",
  weekly: "Week",
  monthly: "Month",
  quarterly: "Quarter",
  yearly: "Year",
};

const DEFAULT_SPARK_CHART_METRICS_MAP = {
  clicks: "clicks",
  cpm: "cpm",
  cpx: "cpx",
  impressions: "impressions",
  revenue: "revenue",
  roas: "roas",
  spend: "spend",
  volume: "volume",
};

interface SingleChannelPageProps extends RouteComponentProps {
  columnMetaDataMap: ColumnMetaDataMap;
  dataFetchKey: (args) => string;
  dateIncrementOptions?: ("daily" | "weekly" | "monthly" | "quarterly" | "yearly")[];
  dates: DateRange;
  defaultKPI?: string;
  defaultLag?: S.LagOption;
  defaultSparkChartMetrics?: string[];
  deletePagePreset: (params: DeletePagePresetParams) => Promise<void>;
  deleteTablePreset: (params: DeleteTablePresetParams) => Promise<void>;
  deliveryDimensionMap: Record<string, string>;
  deliveryMetricOptions?: DeliveryMetricOption[];
  dimensionColumnMetaDataMap: ColumnMetaDataMap;
  fetchingPagePresets: boolean;
  fetchingTablePresets: boolean;
  filterData: P.GetFilterOptionsResponse;
  filterDataProcessor?: (data: P.GetFilterOptionsResponse, ...args) => P.GetFilterOptionsResponse;
  getDeliveryData: (params) => Promise<DailyDeliveryData>;
  getPerformanceData: (
    params
  ) => Promise<S.GetPageDataResponse & L.GetPageDataResponse & Y.GetPageDataResponse>;
  getDimensionCell: (
    dimensionData: DimensionMap,
    dimensionHeader: DimensionColumn,
    ...args: any[]
  ) => CellData;
  getTablePreset: (params: GetTablePresetParams) => Promise<MetricsTablePreset | undefined>;
  glossary?: GlossaryItem[];
  isGraph?: boolean;
  kpiMetaData: P.GetKpiMetaDataResponse;
  pagePresets: MetricsPagePreset[];
  percentAOIPlaceholder?: number;
  prefix: SingleChannelMediaTypes;
  savePagePreset: (body: Partial<SavePagePresetParams>) => Promise<{ id: number; name: string }>;
  saveTablePreset: (body: Partial<SaveTablePresetParams>) => Promise<{ id: number; name: string }>;
  setDates: StateSetter<DateRange>;
  setPagePresets: StateSetter<MetricsPagePreset[] | undefined>;
  setTablePresets: StateSetter<MetricsTablePreset[] | undefined>;
  sparkChartMetricPrettyNameMap?: Record<string, string>;
  sparkChartMetricsMap: Record<string, string>;
  tablePresets: PresetIDGroups[];
  urlSettings: string;
  useCrossChannelKpis?: boolean;
}

const SingleChannelPage = typedReactMemo<React.FC<SingleChannelPageProps>>(
  ({
    columnMetaDataMap = {},
    dataFetchKey,
    dateIncrementOptions = ["daily", "weekly", "monthly", "quarterly", "yearly"],
    dates,
    defaultKPI,
    defaultLag,
    defaultSparkChartMetrics = ["spend", "impressions", "volume", "roas"],
    deletePagePreset,
    deleteTablePreset,
    deliveryDimensionMap = {},
    deliveryMetricOptions,
    dimensionColumnMetaDataMap = {},
    fetchingPagePresets,
    fetchingTablePresets,
    filterData,
    filterDataProcessor = (filterData, ...args) => filterData,
    getDeliveryData,
    getDimensionCell,
    getPerformanceData,
    getTablePreset,
    glossary = [],
    isGraph,
    kpiMetaData,
    location,
    pagePresets,
    percentAOIPlaceholder,
    prefix,
    savePagePreset,
    saveTablePreset,
    setDates,
    setPagePresets,
    setTablePresets,
    sparkChartMetricPrettyNameMap = {},
    sparkChartMetricsMap = DEFAULT_SPARK_CHART_METRICS_MAP,
    tablePresets,
    urlSettings,
    useCrossChannelKpis = false,
  }) => {
    const [branchBuild] = useState("v2/latest");
    const [dataMap, setDataMap] = useMap<string, S.GetPageDataResponse | undefined>();
    const [dataStatusMap, setDataStatusMap] = useMap<string, string | undefined>();
    const [deliveryDataMap, setDeliveryDataMap] = useMap<string, any>();
    const [deliveryDataStatusMap, setDeliveryDataStatusMap] = useMap<string, string | undefined>();
    const [deliveryDimension, setDeliveryDimension] = useState<string>(
      R.keys(deliveryDimensionMap)[0]
    );

    const [exportedData, setExportedData] = useState<(string | number)[][]>([]);
    const [filter, setFilter] = useState<{ filter: (line: Record<string, any>) => boolean }>();
    const [filteredGlossary, setFilteredGlossary] = useState<GlossaryItem[]>(glossary);
    const [filterID, setFilterID] = useState<number | undefined>();
    const [filters, setFilters] = useState<FilterPreset[]>();
    const [filterSource, setFilterSource] = useState<
      "presetFilterID" | "currentFilterID" | "presetState" | "currentState"
    >();
    const [filterState, setFilterState, resetFilterState] = useFilterPaneState();
    const [loading, setLoading] = useState<boolean>(false);
    const [otherDates, setOtherDates] = useState<DateRange>();
    const [pageGroupName, setPageGroupName] = useState<string>("");
    const [pagePresetChanges, setPagePresetChanges] = useState<MetricsPagePreset>({
      globalOptions: {},
    } as MetricsPagePreset);
    const [pagePresetName, setPagePresetName] = useState<string>();
    const [tablePresetName, setTablePresetName] = useState<string>();
    const [pickerAudience, setPickerAudience] = useState<L.Audience>();
    const [pickerKPI, setPickerKPI] = useState<string>("");
    const [pickerLag, setPickerLag] = useState<S.LagOption>();
    const [rerenderTable, setRerenderTable] = useState(false);
    const [saving, setSaving] = useState(false);
    const [selectedTab, setSelectedTab] = useState<string>();
    const [showColorScale, setShowColorScale] = useState<boolean>(false);
    const [showEditPagePresetModal, setShowEditPagePresetModal] = useState<boolean>(false);
    const [showEditTablePresetModal, setShowEditTablePresetModal] = useState<boolean>(false);
    const [showGlossary, setShowGlossary] = useState<boolean>(false);
    const [showNewPagePresetModal, setShowNewPagePresetModal] = useState<boolean>(false);
    const [showNewTablePresetModal, setShowNewTablePresetModal] = useState<boolean>(false);
    const [showRenamePagePresetModal, setShowRenamePagePresetModal] = useState<boolean>(false);
    const [showRenameTablePresetModal, setShowRenameTablePresetModal] = useState<boolean>(false);
    const [tableHeight, setTableHeight] = useState(250);
    const [tablePresetChanges, setTablePresetChanges] = useState<MetricsTablePreset>(
      {} as MetricsTablePreset
    );
    const [tablePresetMap, setTablePresetMap] = useMap<string, MetricsTablePreset | undefined>();
    const [tableWidth, setTableWidth] = useState(500);
    const { company } = useLocation();
    const companyInfo = useCompanyInfo();
    const containerRef = useRef(null);
    const mediaTypes = useSelector(mediaTypesSelector(company));
    const pagePresetURLString = "_page-preset=";
    const setError = useSetError();
    const tablePresetURLString = "_table-preset=";

    const mediaTypeEnabled = useMemo(
      () =>
        mediaTypes.includes(prefix === "linear" ? "tv" : prefix) ||
        (prefix === "searchShopping" &&
          (mediaTypes.includes(SEARCH) || mediaTypes.includes(SHOPPING))),
      [mediaTypes, prefix]
    );
    const baseUrl = useMemo(() => {
      return getBaseURL(urlSettings, location);
    }, [urlSettings, location]);

    const [startingDateRange, startingOtherDateRange] = useMemo(() => {
      const localDateRangeString = localStorage.getItem(`${company}_${prefix}PerfDateRange`);
      const localDateRange = localDateRangeString ? JSON.parse(localDateRangeString) : null;
      const localOtherDateRangeString = localStorage.getItem(
        `${company}_${prefix}OtherPerfDateRange`
      );
      const localOtherDateRange = localOtherDateRangeString
        ? JSON.parse(localOtherDateRangeString)
        : null;
      return [
        {
          start:
            localDateRange && localDateRange.start
              ? localDateRange.start
              : computeResolvedDate({
                  adjustmentType: "month",
                  adjustment: 1,
                  pivotDate: "today",
                }),
          end:
            localDateRange && localDateRange.end
              ? localDateRange.end
              : computeResolvedDate({
                  pivotDate: "today",
                }),
        },
        {
          start:
            localOtherDateRange && localOtherDateRange.start
              ? localOtherDateRange.start
              : computeResolvedDate({
                  adjustmentType: "month",
                  adjustment: 2,
                  pivotDate: "yesterday",
                }),
          end:
            localOtherDateRange && localOtherDateRange.end
              ? localOtherDateRange.end
              : computeResolvedDate({
                  adjustmentType: "month",
                  adjustment: 1,
                  pivotDate: "yesterday",
                }),
        },
      ];
    }, [company, prefix]);

    const setURL = useCallback(
      (pagePresetName: string, tablePresetName?: string, selectedTabInput?: string) => {
        // To avoid infinite renders, only update the URL and page preset if it's different from
        // the current state
        const newURL = `${location?.origin}${baseUrl}/${R.defaultTo(
          selectedTab,
          selectedTabInput
        )}${pagePresetURLString}${encodePrettyUrl(pagePresetName)}${
          tablePresetName ? `${tablePresetURLString}${encodePrettyUrl(tablePresetName)}` : ""
        }`;
        const isLocationDifferent = location && location.href !== newURL;
        if (selectedTab && isLocationDifferent) {
          setPagePresetName(pagePresetName);
          setTablePresetName(tablePresetName);
          navigate(newURL);
          Mixpanel.track(MxE.PAGE_PRESET_CHANGE, {
            option_selected: pagePresetName,
          });
        }
      },
      [location, baseUrl, selectedTab]
    );

    const [tablePreset, tablePresetID] = useMemo(() => {
      if (tablePresetName && tablePresets && tablePresetMap) {
        const tablePresetID = R.find(preset => preset.name === tablePresetName, tablePresets);
        if (tablePresetID) {
          return [tablePresetMap[`${tablePresetID?.id}`], tablePresetID.id];
        }
      }
      return [undefined, undefined];
    }, [tablePresetMap, tablePresetName, tablePresets]);

    const pagePreset = useMemo(() => {
      if (pagePresets && tablePresets && !fetchingPagePresets && !fetchingTablePresets) {
        const pagePreset = R.find(
          (preset: MetricsPagePreset) => preset.name === pagePresetName,
          pagePresets
        );
        if (pagePreset) {
          // Set Filter Info
          if (pagePreset.filterInfo && R.isNil(filterSource)) {
            if (!R.isNil(pagePreset.filterInfo.filterID)) {
              setFilterSource("presetFilterID");
              setFilterID(pagePreset.filterInfo.filterID);
            } else if (pagePreset.filterInfo.filterState) {
              setFilterSource("presetState");
              setFilterState(pagePreset.filterInfo.filterState);
            } else {
              setFilterSource("currentState");
              resetFilterState();
            }
          }
          // Set Global Options
          if (pagePreset.globalOptions) {
            setPickerAudience(pagePreset.globalOptions.audience || "HH");
            setPickerKPI(
              pagePreset?.globalOptions.kpi ||
                defaultKPI ||
                (useCrossChannelKpis
                  ? companyInfo.cross_channel_kpis[0]
                  : companyInfo.streaming_performance_default_kpi)
            );
            setPickerLag(
              pagePreset?.globalOptions.lag ||
                defaultLag ||
                companyInfo.streaming_performance_default_lag
            );
          }
          // Set Dates
          if (pagePreset.dateInfo) {
            const {
              start,
              end,
              dateType,
              relative_date_info,
              comparison_start,
              comparison_end,
              comparison_dateType,
              comparison_relative_date_info,
            } = pagePreset.dateInfo;

            let resolvedPrimaryDates: DateRange | undefined;
            let resolvedComparisonDates: DateRange | undefined;
            if (R.isNil(dates)) {
              if (
                dateType === "relative" &&
                relative_date_info &&
                relative_date_info !== "Custom"
              ) {
                if (typeof relative_date_info === "string") {
                  const newRange = computeDateFromPreset(relative_date_info);
                  resolvedPrimaryDates = newRange;
                } else {
                  const newRange = computeCustomRelativeRange(
                    relative_date_info.start,
                    relative_date_info.end
                  );
                  resolvedPrimaryDates = newRange;
                }
              } else if (start && end) {
                resolvedPrimaryDates = {
                  start: start,
                  end: end,
                };
              }

              if (resolvedPrimaryDates) {
                setDates(resolvedPrimaryDates);
              } else {
                setDates(startingDateRange);
              }
            }
            if (R.isNil(otherDates)) {
              if (
                comparison_dateType === "relative" &&
                comparison_relative_date_info &&
                comparison_relative_date_info !== "Custom" &&
                resolvedPrimaryDates
              ) {
                if (typeof comparison_relative_date_info === "string") {
                  const newRange = computeDateFromComparisonPreset(
                    comparison_relative_date_info,
                    resolvedPrimaryDates.start,
                    resolvedPrimaryDates.end
                  );
                  resolvedComparisonDates = newRange;
                } else {
                  const newRange = computeCustomRelativeRange(
                    comparison_relative_date_info.start,
                    comparison_relative_date_info.end
                  );
                  resolvedComparisonDates = newRange;
                }
              } else if (comparison_start && comparison_end) {
                resolvedComparisonDates = {
                  start: comparison_start,
                  end: comparison_end,
                };
              }

              if (!resolvedComparisonDates) {
                resolvedComparisonDates = startingOtherDateRange;
              }

              setOtherDates(resolvedComparisonDates);
            }
          } else {
            if (R.isNil(dates)) {
              setDates(startingDateRange);
            }
            if (R.isNil(otherDates)) {
              setOtherDates(startingOtherDateRange);
            }
          }
          // Set Table Preset
          if (R.isNil(tablePresetID) && tablePresetMap && pagePreset.globalOptions) {
            const pageSourcedTableID = pagePreset.globalOptions.tableID;
            const pageSourcedTablePreset = R.find(
              preset => preset.id === pageSourcedTableID,
              tablePresets
            );
            if (pageSourcedTablePreset) {
              setURL(pagePreset.name, pageSourcedTablePreset.name);
            } else if (tablePresets && tablePresets.length && tablePresets[0]) {
              pagePreset.globalOptions.tableID = tablePresets[0].id;
              setURL(pagePreset.name, tablePresets[0].name);
            }
          }
          // Set Delivery Dimension
          if (pagePreset.globalOptions && pagePreset.globalOptions.deliveryDimension) {
            setDeliveryDimension(pagePreset.globalOptions.deliveryDimension);
          }
          setPagePresetChanges(pagePreset);
          return pagePreset;
        }
      }
    }, [
      companyInfo,
      dates,
      defaultKPI,
      defaultLag,
      fetchingPagePresets,
      fetchingTablePresets,
      filterSource,
      otherDates,
      pagePresetName,
      pagePresets,
      resetFilterState,
      setDates,
      setFilterState,
      setURL,
      startingDateRange,
      startingOtherDateRange,
      tablePresetID,
      tablePresetMap,
      tablePresets,
      useCrossChannelKpis,
    ]);

    const pagePresetGroups = useMemo(() => {
      const groupsMap = {};
      if (pagePresets) {
        for (const preset of pagePresets) {
          if (preset.presetGroup && preset.presetGroup !== STANDARD_GROUP_NAME) {
            groupsMap[preset.presetGroup] = true;
          }
        }
      }
      return R.keys(groupsMap).sort();
    }, [pagePresets]);

    // These global options can come from the pickers, page preset, or default company settings
    // Order of importance would be pickers > page preset > company settings
    const [fetchAudience, fetchKPI, fetchLag, globalBrand] = useMemo(() => {
      const defAudience: L.Audience = "HH";
      const defKpi = R.defaultTo(companyInfo.streaming_performance_default_kpi, defaultKPI);
      const defLag: S.LagOption = R.defaultTo(
        companyInfo.streaming_performance_default_lag,
        defaultLag
      );
      const { audience: presetAudience, kpi: presetKPI, lag: presetLag } =
        pagePreset && pagePreset.globalOptions
          ? pagePreset.globalOptions
          : {
              audience: undefined,
              kpi: undefined,
              lag: undefined,
            };
      return [
        pickerAudience ? pickerAudience : presetAudience ? presetAudience : defAudience,
        pickerKPI ? pickerKPI : presetKPI ? presetKPI : defKpi,
        pickerLag ? pickerLag : presetLag ? presetLag : defLag,
        getGlobalBrand(
          companyInfo,
          R.defaultTo(defAudience, R.defaultTo(presetAudience, pickerAudience))
        ),
      ];
    }, [companyInfo, defaultKPI, defaultLag, pagePreset, pickerAudience, pickerKPI, pickerLag]);

    const fetchKey = useMemo(() => {
      if (
        pagePreset &&
        dates &&
        branchBuild &&
        (fetchKPI || useCrossChannelKpis) &&
        fetchLag &&
        fetchAudience &&
        !R.isNil(tablePresetID)
      ) {
        const customFilterSettings =
          filterState && pagePreset.filterInfo.filterState
            ? R.equals(filterState, pagePreset.filterInfo.filterState)
            : false;
        return `${pagePreset.id}_${dates.start}_${dates.end}_${branchBuild}_${fetchKPI}_${fetchLag}_${fetchAudience}_${filterID}_${tablePresetID}_${selectedTab}_${customFilterSettings}_${deliveryDimension}`;
      }
      return "";
    }, [
      branchBuild,
      dates,
      deliveryDimension,
      fetchAudience,
      fetchKPI,
      fetchLag,
      filterID,
      filterState,
      pagePreset,
      selectedTab,
      tablePresetID,
      useCrossChannelKpis,
    ]);

    const otherFetchKey = useMemo(() => {
      if (
        pagePreset &&
        otherDates &&
        branchBuild &&
        (fetchKPI || useCrossChannelKpis) &&
        fetchLag &&
        fetchAudience &&
        !R.isNil(tablePresetID)
      ) {
        const customFilterSettings =
          filterState && pagePreset.filterInfo.filterState
            ? R.equals(filterState, pagePreset.filterInfo.filterState)
            : false;
        return `${pagePreset.id}_${otherDates.start}_${otherDates.end}_${branchBuild}_${fetchKPI}_${fetchLag}_${fetchAudience}_${filterID}_${tablePresetID}_${selectedTab}_${customFilterSettings}_${deliveryDimension}`;
      }
      return "";
    }, [
      branchBuild,
      deliveryDimension,
      fetchAudience,
      fetchKPI,
      fetchLag,
      filterID,
      filterState,
      otherDates,
      pagePreset,
      selectedTab,
      tablePresetID,
      useCrossChannelKpis,
    ]);

    const data = dataMap[fetchKey];
    const otherData = dataMap[otherFetchKey];
    const deliveryData = deliveryDataMap[fetchKey];
    const otherDeliveryData = deliveryDataMap[otherFetchKey];

    useEffect(() => {
      if (R.isNil(selectedTab)) {
        if (urlSettings.startsWith(TabKey.DELIVERY)) {
          setSelectedTab(TabKey.DELIVERY);
        } else if (urlSettings.startsWith(TabKey.METRICS_TABLE)) {
          setSelectedTab(TabKey.METRICS_TABLE);
        } else if (urlSettings.startsWith(TabKey.OVERVIEW)) {
          setSelectedTab(TabKey.OVERVIEW);
        } else {
          setSelectedTab(TabKey.OVERVIEW);
        }
      }
    }, [selectedTab, urlSettings]);

    useEffect(() => {
      if (
        selectedTab &&
        pagePresets &&
        pagePresets.length &&
        tablePresets &&
        tablePresets.length &&
        !fetchingPagePresets &&
        !fetchingTablePresets
      ) {
        const decodedURLPagePresetName = decodePrettyUrl(
          urlSettings.substring(
            selectedTab.length + pagePresetURLString.length,
            urlSettings.indexOf(tablePresetURLString)
          )
        );
        const decodedURLTablePresetName = decodePrettyUrl(
          urlSettings.substring(
            urlSettings.indexOf(tablePresetURLString) + tablePresetURLString.length,
            urlSettings.length
          )
        );
        if (R.isNil(tablePresetName) && decodedURLTablePresetName) {
          setTablePresetName(decodedURLTablePresetName);
        }
        if (R.isNil(pagePresetName) && decodedURLPagePresetName) {
          setPagePresetName(decodedURLPagePresetName);
        }
        const urlPagePreset = R.find(
          preset => preset.name === decodedURLPagePresetName,
          pagePresets
        );
        const urlTablePreset = R.find(
          preset => preset.name === decodedURLTablePresetName,
          tablePresets
        );
        if (R.isNil(urlPagePreset) || (pagePreset && pagePreset.name !== urlPagePreset.name)) {
          if (urlPagePreset) {
            setURL(urlPagePreset.name, urlTablePreset?.name);
            if (urlPagePreset.presetGroup && urlPagePreset.presetGroup.length > 0) {
              setPageGroupName(urlPagePreset.presetGroup);
            } else {
              setPageGroupName(ALL_GROUP_NAME);
            }
          } else if (pagePresets && pagePresets.length) {
            for (const preset of pagePresets) {
              if (!preset.temporary) {
                setURL(preset.name, urlTablePreset?.name);
                setPageGroupName(R.defaultTo(ALL_GROUP_NAME, preset.presetGroup));
                break;
              }
            }
          }
        }
      }
    }, [
      fetchingPagePresets,
      fetchingTablePresets,
      pageGroupName,
      pagePreset,
      pagePresetName,
      pagePresets,
      selectedTab,
      setURL,
      tablePreset,
      tablePresetName,
      tablePresets,
      urlSettings,
    ]);

    useEffect(() => {
      if (
        !R.isNil(tablePresetID) &&
        !tablePreset &&
        pagePreset &&
        tablePresetMap &&
        R.map(preset => preset.id, R.defaultTo([], tablePresets) as PresetIDGroups[]).includes(
          tablePresetID
        )
      ) {
        (async () => {
          try {
            const tablePreset = await getTablePreset({
              id: tablePresetID,
              company,
              mediatype: prefix,
            });
            if (tablePreset) {
              setTablePresetMap(`${tablePresetID}`, tablePreset);
              setTablePresetChanges(tablePreset);
              setURL(pagePreset.name, tablePreset.name);
            }
          } catch (e) {
            const error = e as Error;
            setError({
              reportError: error,
              message: error.message,
            });
          }
        })();
      } else if (pagePreset && tablePresetMap[`${tablePresetID}`]) {
        setURL(pagePreset.name, (tablePresetMap[`${tablePresetID}`] as MetricsTablePreset).name);
      }
    }, [
      setError,
      company,
      prefix,
      companyInfo,
      startingDateRange,
      tablePreset,
      getTablePreset,
      setTablePresetMap,
      pagePreset,
      tablePresets,
      tablePresetMap,
      setURL,
      tablePresetName,
      tablePresetID,
    ]);

    const onDatePickerChange = useCallback(
      (dates: DateRange) => {
        localStorage.setItem(`${company}_${prefix}PerfDateRange`, JSON.stringify(dates));
        setDates(dates);
      },
      [company, prefix, setDates]
    );

    const onOtherDatePickerChange = useCallback(
      (otherDates: DateRange) => {
        localStorage.setItem(`${company}_${prefix}OtherPerfDateRange`, JSON.stringify(otherDates));
        setOtherDates(otherDates);
      },
      [company, prefix, setOtherDates]
    );

    const resetDefaultDates = useCallback(() => {
      localStorage.removeItem(`${company}_${prefix}PerfDateRange`);
      localStorage.removeItem(`${company}_${prefix}otherPerfDateRange`);
      setDates({
        start: computeResolvedDate(S.DEFAULT_DATE_RANGE_STATE.start),
        end: computeResolvedDate(S.DEFAULT_DATE_RANGE_STATE.end),
      });
      setOtherDates({
        start: computeResolvedDate(S.DEFAULT_DATE_RANGE_STATE.start),
        end: computeResolvedDate(S.DEFAULT_DATE_RANGE_STATE.end),
      });
    }, [company, prefix, setDates]);

    // Delivery Data
    const fetchDeliveryData = useCallback(
      (key, dates) => {
        (async () => {
          try {
            setLoading(true);
            const dateRangeToUse = overrideDateRange(company, dates);
            let params: any = {
              agency: companyInfo.agency,
              company,
              dates: dateRangeToUse,
              dimension: deliveryDimensionMap[deliveryDimension],
              filterState: {
                advanced: "",
                basic: { notMap: {}, selectedMap: {} },
                isAdvanced: false,
              },
              prefix,
              // The following params are used for the new Single Channel delivery lambda. Will probably
              // refactor this once we have all channels using it.
              channel: prefix,
              start: dateRangeToUse?.start,
              end: dateRangeToUse?.end,
            };
            if (fetchKPI) {
              params.kpi = fetchKPI;
            }
            if (fetchAudience) {
              params.audience = fetchAudience;
            }
            // We don't want to check for the filter state if we don't have a preset and
            // if we haven't loaded the filter state from the page/filter preset
            if (filterSource === "currentState" && !R.isNil(filterState)) {
              params.filterState = filterState;
            } else if (filterSource === "currentFilterID" && !R.isNil(filterID)) {
              params.filterID = filterID;
            } else if (
              pagePreset &&
              filterSource === "presetFilterID" &&
              !R.isNil(pagePreset.filterInfo.filterID)
            ) {
              params.filterID = pagePreset.filterInfo.filterID;
            } else if (
              pagePreset &&
              filterSource === "presetState" &&
              pagePreset.filterInfo.filterState
            ) {
              params.filterState = pagePreset.filterInfo.filterState;
            }
            const data = await getDeliveryData(params);
            setDeliveryDataMap(key, data);
          } catch (e) {
            setDeliveryDataStatusMap(key, "readyToFetch");
            const error = e as Error;
            setError({
              reportError: error,
              message: error.message,
            });
          } finally {
            setLoading(false);
          }
        })();
      },
      [
        company,
        companyInfo.agency,
        deliveryDimension,
        deliveryDimensionMap,
        fetchAudience,
        fetchKPI,
        filterID,
        filterSource,
        filterState,
        getDeliveryData,
        pagePreset,
        prefix,
        setDeliveryDataMap,
        setDeliveryDataStatusMap,
        setError,
      ]
    );

    useEffect(() => {
      if (
        selectedTab === TabKey.DELIVERY &&
        fetchKey &&
        deliveryDataStatusMap[fetchKey] === "readyToFetch"
      ) {
        if (R.isNil(deliveryDataMap[fetchKey])) {
          try {
            fetchDeliveryData(fetchKey, dates);
          } catch (e) {
            console.error(
              `Failed to fetch delivery data for ${company}, ${fetchKey}, ${dates}. Refresh to retry`
            );
            setDeliveryDataStatusMap(fetchKey, "failed");
          }
        }
        setDeliveryDataStatusMap(fetchKey, "fetched");
      }
    }, [
      company,
      dataMap,
      dataStatusMap,
      dates,
      deliveryDataMap,
      deliveryDataStatusMap,
      fetchDeliveryData,
      fetchKey,
      selectedTab,
      setDataStatusMap,
      setDeliveryDataStatusMap,
    ]);

    useEffect(() => {
      if (
        selectedTab === TabKey.DELIVERY &&
        otherFetchKey &&
        deliveryDataStatusMap[otherFetchKey] === "readyToFetch"
      ) {
        if (R.isNil(deliveryDataMap[otherFetchKey])) {
          try {
            fetchDeliveryData(otherFetchKey, otherDates);
          } catch (e) {
            console.error(
              `Failed to fetch delivery data for ${company}, ${otherFetchKey}, ${otherDates}. Refresh to retry`
            );
            setDeliveryDataStatusMap(otherFetchKey, "failed");
          }
        }
        setDeliveryDataStatusMap(otherFetchKey, "fetched");
      }
    }, [
      company,
      dataMap,
      dataStatusMap,
      deliveryDataMap,
      deliveryDataStatusMap,
      fetchDeliveryData,
      otherDates,
      otherFetchKey,
      selectedTab,
      setDataStatusMap,
      setDeliveryDataStatusMap,
    ]);

    useEffect(() => {
      if (fetchKey && R.isNil(deliveryDataStatusMap[fetchKey]) && !deliveryData) {
        setDeliveryDataStatusMap(fetchKey, "readyToFetch");
      }
    }, [
      branchBuild,
      company,
      data,
      dataStatusMap,
      dates,
      deliveryData,
      deliveryDataStatusMap,
      fetchDeliveryData,
      fetchKey,
      fetchKPI,
      fetchLag,
      setDataMap,
      setDataStatusMap,
      setDeliveryDataStatusMap,
      setError,
    ]);

    useEffect(() => {
      if (otherFetchKey && R.isNil(deliveryDataStatusMap[otherFetchKey]) && !otherDeliveryData) {
        setDeliveryDataStatusMap(otherFetchKey, "readyToFetch");
      }
    }, [
      branchBuild,
      company,
      data,
      dataStatusMap,
      dates,
      deliveryDataStatusMap,
      fetchDeliveryData,
      fetchKey,
      fetchKPI,
      fetchLag,
      otherData,
      otherDeliveryData,
      otherFetchKey,
      setDataMap,
      setDataStatusMap,
      setDeliveryDataStatusMap,
      setError,
    ]);

    // Performance Data
    const fetchPerformanceData = useCallback(
      (key, dates) => {
        (async () => {
          try {
            setLoading(true);
            const [branch, build] = branchBuild.split("/");
            let params: any = {
              ...(dates || {}),
              pageID: R.defaultTo({ id: 0 }, pagePreset).id,
              company,
              branch,
              build,
              filterID,
              id: 0, // TODO: remove this
              tableID: tablePresetID,
            };
            if (fetchKPI) {
              params.kpi = fetchKPI;
            }
            if (fetchLag) {
              params.lag = fetchLag;
            }
            if (fetchAudience) {
              params.audience = fetchAudience;
            }
            if (!R.isNil(pagePreset) && !R.isNil(pagePreset.globalOptions)) {
              if (!R.isNil(tablePresetID) && tablePresetID !== pagePreset.globalOptions.tableID) {
                params.tableID = tablePresetID;
              }
              if (pagePreset.globalOptions.shouldUseUnequivalizedImpressions) {
                params.shouldUseUnequivalizedImpressions =
                  pagePreset.globalOptions.shouldUseUnequivalizedImpressions;
              }
            }
            // We don't want to check for the filter state if we don't have a preset and
            // if we haven't loaded the filter state from the page/filter preset
            if (filterSource === "currentState" && !R.isNil(filterState)) {
              params.filterState = JSON.stringify(filterState);
            } else if (filterSource === "currentFilterID" && !R.isNil(filterID)) {
              params.filterID = filterID;
            }
            if (selectedTab === TabKey.OVERVIEW) {
              params.byDate = "dateOnly";
            }
            const data = await getPerformanceData(params);
            setDataMap(key, data);
            if (selectedTab === TabKey.METRICS_TABLE) {
              setLoading(false);
            }
          } catch (e) {
            setDataStatusMap(key, "readyToFetch");
            const error = e as Error;
            setError({
              reportError: error,
              message: error.message,
            });
            setLoading(false);
          } finally {
            setRerenderTable(true);
          }
        })();
      },
      [
        branchBuild,
        company,
        pagePreset,
        filterID,
        fetchKPI,
        fetchLag,
        fetchAudience,
        filterState,
        filterSource,
        selectedTab,
        getPerformanceData,
        setDataMap,
        tablePresetID,
        setDataStatusMap,
        setError,
      ]
    );

    useEffect(() => {
      if (
        (selectedTab === TabKey.OVERVIEW || selectedTab === TabKey.METRICS_TABLE) &&
        fetchKey &&
        dataStatusMap[fetchKey] === "readyToFetch"
      ) {
        if (R.isNil(dataMap[fetchKey])) {
          try {
            fetchPerformanceData(fetchKey, dates);
          } catch (e) {
            console.error(
              `Failed to fetch performance data for ${company}, ${fetchKey}, ${dates}. Refresh to retry`
            );
            setDataStatusMap(fetchKey, "failed");
          }
        }
        setDataStatusMap(fetchKey, "fetched");
      }
    }, [
      company,
      dataMap,
      dataStatusMap,
      dates,
      fetchPerformanceData,
      fetchKey,
      setDataStatusMap,
      selectedTab,
    ]);

    useEffect(() => {
      if (
        selectedTab === TabKey.OVERVIEW &&
        otherFetchKey &&
        dataStatusMap[otherFetchKey] === "readyToFetch"
      ) {
        if (R.isNil(dataMap[otherFetchKey])) {
          try {
            fetchPerformanceData(otherFetchKey, otherDates);
          } catch (e) {
            console.error(
              `Failed to fetch performance data for ${company}, ${otherFetchKey}, ${otherDates}. Refresh to retry`
            );
            setDataStatusMap(otherFetchKey, "failed");
          }
        }
        setDataStatusMap(otherFetchKey, "fetched");
      }
    }, [
      company,
      dataMap,
      dataStatusMap,
      fetchPerformanceData,
      otherDates,
      otherFetchKey,
      selectedTab,
      setDataStatusMap,
    ]);

    useEffect(() => {
      if (fetchKey && R.isNil(dataStatusMap[fetchKey]) && !data) {
        setDataStatusMap(fetchKey, "readyToFetch");
      }
    }, [
      branchBuild,
      company,
      data,
      dataStatusMap,
      dates,
      fetchPerformanceData,
      fetchKey,
      fetchKPI,
      fetchLag,
      setDataMap,
      setDataStatusMap,
      setError,
    ]);

    useEffect(() => {
      if (otherFetchKey && R.isNil(dataStatusMap[otherFetchKey]) && !otherData) {
        setDataStatusMap(otherFetchKey, "readyToFetch");
      }
    }, [
      branchBuild,
      company,
      data,
      dataStatusMap,
      dates,
      fetchPerformanceData,
      fetchKey,
      fetchKPI,
      fetchLag,
      otherData,
      otherFetchKey,
      setDataMap,
      setDataStatusMap,
      setError,
    ]);

    const copyURLToClipboard = useCallback((url: string) => {
      const el = window.document.createElement("textarea");
      el.value = url;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      window.document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      alert(`Copied new link to clipboard: ${url}`);
    }, []);

    const savePagePresetToDB = useCallback(
      async (newPagePreset: MetricsPagePreset, copyLink = false) => {
        if (newPagePreset) {
          if (!newPagePreset.temporary && newPagePreset.name === "") {
            setError({
              title: "Blank Name",
              message: "Your view must have a name.",
            });
            return;
          }
          setSaving(true);
          let body: SavePagePresetParams = {
            company: globalBrand || company,
            companyToUseInDB: company,
            group: newPagePreset.presetGroup,
            id: newPagePreset.id ? newPagePreset.id : "new",
            mediatype: prefix,
            name: newPagePreset.name,
            preset: newPagePreset,
            temporary: newPagePreset.temporary,
          };
          try {
            const { id, message, name } = (await savePagePreset(body)) as any;
            if (message) {
              throw new Error(message);
            }
            const newURL = `${location?.origin}${baseUrl}/${pagePresetURLString}${encodePrettyUrl(
              name
            )}`;
            if (pagePreset && newPagePreset.id === pagePreset.id) {
              navigate && navigate(newURL);
            }
            if (window && window.document && copyLink) {
              copyURLToClipboard(newURL);
            }
            if (
              (pagePreset && newPagePreset.id === pagePreset.id) ||
              body.id === "new" ||
              body.id <= 0
            ) {
              setPagePresetChanges(newPagePreset);
              setURL(name);
            }
            setDataMap(fetchKey, undefined);
            setDataStatusMap(fetchKey, undefined);
            setPagePresets(presets => [
              ...R.defaultTo([], presets),
              {
                ...newPagePreset,
                id,
                name,
                temporary: !!body.temporary,
              },
            ]);
          } catch (e) {
            const error = e as Error;
            setError({
              title: "Couldn't save view",
              message: error.message,
              reportError: error,
            });
          } finally {
            setSaving(false);
          }
        }
      },
      [
        globalBrand,
        company,
        prefix,
        setError,
        savePagePreset,
        location?.origin,
        baseUrl,
        pagePreset,
        setURL,
        setDataMap,
        fetchKey,
        setDataStatusMap,
        setPagePresets,
        copyURLToClipboard,
      ]
    );

    ////////////////////////////
    // PAGE CONTROLS
    ////////////////////////////
    const shareButton = (
      <OverlayTrigger
        overlay={<Tooltip id={uuid.v4()}>Copy link to current view</Tooltip>}
        placement="bottom center"
      >
        <Button
          icon={<MdLink />}
          size={"lg"}
          type={ButtonType.FILLED}
          variant={ButtonFrameworkVariant.ICON_ONLY}
          onClick={() => {
            if (
              pagePreset &&
              tablePreset &&
              R.equals(pagePreset, pagePresetChanges) &&
              pagePreset.globalOptions &&
              pagePreset.globalOptions.tableID === tablePreset.id &&
              R.equals(tablePreset, tablePresetChanges) &&
              pagePreset.filterInfo &&
              pagePreset.filterInfo.filterID === filterID &&
              location
            ) {
              const url = location.href;
              copyURLToClipboard(url);
            } else {
              savePagePresetToDB(
                {
                  ...pagePresetChanges,
                  company,
                  id: "new" as any,
                  mediatype: prefix,
                  temporary: true,
                },
                true
              );
            }
          }}
        />
      </OverlayTrigger>
    );
    const pagePresetPicker = (
      <PresetDropdown
        addPresetOnClick={() => {
          setShowNewPagePresetModal(true);
          // In the case that we are in the standard group already, clear this out
          setPagePresetChanges(
            preset =>
              ({
                ...preset,
                id: "new",
                name: "New Page Preset!",
                presetGroup: "",
                dateInfo: {
                  ...preset.dateInfo,
                  dateType: "relative",
                  comparison_dateType: "relative",
                  relative_date_info: "Last Month",
                  comparison_relative_date_info: "Default",
                },
              } as any)
          );
        }}
        addPresetText={"Add New Page Preset"}
        applyOnClick={preset => {
          setURL(preset.name);
          setDates(undefined as any);
          setOtherDates(undefined as any);
          setFilterSource(undefined);
        }}
        cancelOnClick={() => {}}
        className={`pagePresetPicker ${fetchingPagePresets ? "loading" : ""}`}
        defaultPresetNames={["Default"]}
        deleteItemOnClick={presetID => deletePagePreset({ id: presetID, mediatype: prefix })}
        duplicateItemOnClick={preset =>
          savePagePreset({
            company,
            id: "new",
            mediatype: prefix,
            preset: preset,
            name: `${preset.name} (Copy)`,
            group: preset.presetGroup,
          })
        }
        editItemOnClick={preset => {
          const presetCopy = { ...preset };
          if (
            preset.dateInfo &&
            preset.dateInfo.dateType === "relative" &&
            !preset.dateInfo.relative_date_info
          ) {
            presetCopy.dateInfo.dateType = "exact";
          }
          if (preset.dateInfo && !preset.dateInfo.comparison_relative_date_info) {
            presetCopy.dateInfo.comparison_dateType = "exact";
          }
          setPagePresetChanges(presetCopy);
          setShowEditPagePresetModal(true);
        }}
        hasPresetGroups={true}
        hasTicks={true}
        label={
          pagePreset && !R.isNil(pagePreset.id)
            ? `Page Preset: ${pagePreset.temporary ? "Temp" : pagePreset.name}`
            : "Load Page Preset"
        }
        presets={R.filter(
          preset => !preset.temporary || (!R.isNil(pagePreset) && preset.id === pagePreset.id),
          pagePresets
        )}
        renameItemOnClick={() => setShowRenamePagePresetModal(true)}
        selectedPreset={pagePreset}
        subTitles={["Preset Group", "Preset"]}
      />
    );

    const kpiData = useKpiPickerData({ company, mediaType: prefix, useCrossChannelKpis });
    const kpiOptions = useMemo(() => {
      const kpiOptions: string[] = [];
      for (const kpiInfo of R.values(R.defaultTo({ kpiMap: {} }, kpiData).kpiMap)) {
        if (kpiInfo.Source) {
          kpiOptions.push(`${kpiInfo.name} (${kpiInfo.Source})`);
        } else {
          kpiOptions.push(kpiInfo.name);
        }
      }
      return R.uniq(kpiOptions);
    }, [kpiData]);

    const { kpiNameMap, nameKpiMap } = useMemo(() => {
      const kpiNameMap = {};
      const nameKpiMap = {};
      for (const kpiRow of R.values(R.defaultTo({ kpiMap: {} }, kpiData).kpiMap)) {
        if (kpiRow.Source) {
          kpiNameMap[`${kpiRow.name} (${kpiRow.Source})`] = kpiRow;
          nameKpiMap[kpiRow.id] = { ...kpiRow, name: `${kpiRow.name} (${kpiRow.Source})` };
        } else {
          kpiNameMap[kpiRow.name] = kpiRow;
          nameKpiMap[kpiRow.id] = kpiRow;
        }
      }
      return { kpiNameMap, nameKpiMap };
    }, [kpiData]);

    const kpiPicker = (
      <Dropdown
        background="dark"
        label="KPI"
        onChange={option => {
          Mixpanel.track(MxE.PAGE_KPI_CHANGE, {
            option_selected: option,
          });
          setPickerKPI(kpiNameMap[option].id);
        }}
        options={kpiOptions}
        size="lg"
        type={DropdownToggleType.OUTLINED}
        value={
          nameKpiMap && pickerKPI && nameKpiMap[pickerKPI] && nameKpiMap[pickerKPI].name
            ? nameKpiMap[pickerKPI].name
            : ""
        }
      />
    );
    const lagPicker = ["audio", "display", "olv", "streaming", "commerce"].includes(prefix) && (
      <Dropdown
        background="dark"
        label="Lag"
        onChange={setPickerLag}
        options={R.filter(lag => prefix !== "commerce" || lag === "14d", S.LAGS)}
        size="lg"
        type={DropdownToggleType.OUTLINED}
        value={fetchLag}
      />
    );
    const audiencePicker = prefix === "linear" && (
      <Dropdown
        background="dark"
        label="Audience"
        onChange={setPickerAudience}
        options={L.AUDIENCES}
        size="lg"
        type={DropdownToggleType.OUTLINED}
        value={fetchAudience}
      />
    );

    const actionsContainer = (
      <div className="performanceActions performanceTitle">
        {shareButton}
        {pagePresetPicker}
        {kpiPicker}
        {lagPicker}
        {audiencePicker}
        {localStorage.getItem(`${company}_${prefix}PerfDateRange`) && (
          <BPMButton size="sm" onClick={resetDefaultDates}>
            Reset Default Dates
          </BPMButton>
        )}
        {selectedTab === TabKey.OVERVIEW || selectedTab === TabKey.DELIVERY ? (
          <DatePicker
            range={dates}
            onChange={onDatePickerChange}
            datePresets={true}
            comparison={true}
            comparisonRange={otherDates}
            comparisonOnChange={onOtherDatePickerChange}
          />
        ) : (
          <DatePicker range={dates} onChange={onDatePickerChange} datePresets={true} />
        )}
      </div>
    );

    const subHeader = pagePreset && (
      <FetchFilterBar
        categories={filterDataProcessor(filterData, tablePreset, deliveryDimension, selectedTab)}
        company={company}
        filterID={filterID}
        filters={filters}
        platform={prefix}
        refreshData={() => {
          setDataMap(fetchKey, undefined);
          setDataStatusMap(fetchKey, "readyToFetch");
          setDataStatusMap(otherFetchKey, "readyToFetch");
          setDeliveryDataMap(fetchKey, undefined);
          setDeliveryDataStatusMap(fetchKey, "readyToFetch");
          setDeliveryDataStatusMap(otherFetchKey, "readyToFetch");
        }}
        setFilterID={setFilterID}
        setFilters={setFilters}
        setFilterSource={
          setFilterSource as StateSetter<
            "presetFilterID" | "currentFilterID" | "presetState" | "currentState"
          >
        }
        setState={setFilterState}
        state={filterState}
      />
    );

    ////////////////////////////
    // OVERVIEW TAB
    ////////////////////////////
    const [overviewData, otherOverviewData] = useMemo(() => {
      const overviewData: MetricTotalsByDate[] = [];
      const otherOverviewData: MetricTotalsByDate[] = [];
      // We will use this to extract data from the fetch, since we don't have an actual column,
      // use all global values.
      const fakeColumn = { audience: fetchAudience, kpi: fetchKPI, lag: fetchLag };
      if (selectedTab === TabKey.OVERVIEW && data && data.data && otherData && otherData.data) {
        for (const row of data.data) {
          const metrics = {
            clicks: 0,
            clicksConversionRate: 0,
            cpc: 0,
            cpm: 0,
            cpx: 0,
            ctr: 0,
            impressions: 0,
            impressionShare: 0,
            impsConversionRate: 0,
            revenue: 0,
            roas: 0,
            spend: 0,
            spots: 0,
            topImpressionShare: 0,
            volume: 0,
          };
          for (const metric of R.keys(sparkChartMetricsMap)) {
            if (!overviewData[metric]) {
              overviewData[metric] = [];
            }
            const fetchKey = dataFetchKey(fakeColumn);
            if (
              row.fetches &&
              row.fetches[fetchKey] &&
              !R.isNil((row.dimensions as any).Date) &&
              validDate((row.dimensions as any).Date)
            ) {
              const metricValue =
                columnMetaDataMap[sparkChartMetricsMap[metric]] &&
                columnMetaDataMap[sparkChartMetricsMap[metric]].fetchGetter
                  ? columnMetaDataMap[sparkChartMetricsMap[metric]].fetchGetter(
                      row.fetches[fetchKey]
                    )
                  : row.fetches[fetchKey][sparkChartMetricsMap[metric]];
              metrics[metric] = metricValue === "--" ? 0 : metricValue;
            }
          }
          overviewData.push({
            date: (row.dimensions as any).Date,
            metrics,
          } as MetricTotalsByDate);
        }
        for (const row of otherData.data) {
          const metrics = {
            clicks: 0,
            clicksConversionRate: 0,
            cpc: 0,
            cpm: 0,
            cpx: 0,
            ctr: 0,
            impressions: 0,
            impressionShare: 0,
            impsConversionRate: 0,
            revenue: 0,
            roas: 0,
            spend: 0,
            spots: 0,
            topImpressionShare: 0,
            volume: 0,
          };
          for (const metric of R.keys(sparkChartMetricsMap)) {
            if (!otherOverviewData[metric]) {
              otherOverviewData[metric] = [];
            }
            const fetchKey = dataFetchKey(fakeColumn);
            if (
              row.fetches &&
              row.fetches[fetchKey] &&
              !R.isNil((row.dimensions as any).Date) &&
              validDate((row.dimensions as any).Date)
            ) {
              const metricValue =
                columnMetaDataMap[sparkChartMetricsMap[metric]] &&
                columnMetaDataMap[sparkChartMetricsMap[metric]].fetchGetter
                  ? columnMetaDataMap[sparkChartMetricsMap[metric]].fetchGetter(
                      row.fetches[fetchKey]
                    )
                  : row.fetches[fetchKey][sparkChartMetricsMap[metric]];
              metrics[metric] = metricValue === "--" ? 0 : metricValue;
            }
          }
          otherOverviewData.push({
            date: (row.dimensions as any).Date,
            metrics,
          } as MetricTotalsByDate);
        }
        const sortedOverviewData = R.sort(
          (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
          overviewData
        );
        const sortedOtherOverviewData = R.sort(
          (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
          otherOverviewData
        );
        setLoading(false);
        return [sortedOverviewData, sortedOtherOverviewData];
      }
      return [undefined, undefined];
    }, [
      columnMetaDataMap,
      data,
      dataFetchKey,
      fetchAudience,
      fetchKPI,
      fetchLag,
      otherData,
      selectedTab,
      sparkChartMetricsMap,
    ]);

    const overviewSparkCharts = useMemo(() => {
      let index = -1;
      return (
        pagePresetChanges &&
        pagePresetChanges.globalOptions && (
          <div className="overviewSparkCharts">
            {R.filter(
              chart => !R.isNil(chart),
              R.map(metric => {
                index = index + 1;
                if (overviewData && otherOverviewData) {
                  return (
                    <SparkChart
                      data={overviewData}
                      defaultMetric={metric}
                      defaultTotal={
                        pagePresetChanges.globalOptions.sparkChartTypes
                          ? pagePresetChanges.globalOptions.sparkChartTypes[index] === "Total"
                          : false
                      }
                      key={metric}
                      otherData={otherOverviewData}
                      metricOptions={R.map(
                        key => ({
                          label: R.defaultTo(
                            sparkChartMetricsMap[key],
                            sparkChartMetricPrettyNameMap[key]
                          ),
                          value: key as any,
                        }),
                        R.keys(sparkChartMetricsMap)
                      )}
                    />
                  );
                } else {
                  return undefined;
                }
              }, R.defaultTo(defaultSparkChartMetrics, pagePresetChanges.globalOptions.sparkCharts) as Metric[])
            )}
          </div>
        )
      );
    }, [
      defaultSparkChartMetrics,
      otherOverviewData,
      overviewData,
      pagePresetChanges,
      sparkChartMetricPrettyNameMap,
      sparkChartMetricsMap,
    ]);
    const overviewCompareMetricsChart = overviewData && otherOverviewData && (
      <div className="overviewCompareMetricsChart">
        <CompareMetrics
          data={overviewData}
          dates={dates}
          otherData={otherOverviewData}
          otherDates={otherDates}
          defaultMetrics={
            pagePresetChanges &&
            pagePresetChanges.globalOptions &&
            pagePresetChanges.globalOptions &&
            pagePresetChanges.globalOptions.compareMetrics
              ? pagePresetChanges.globalOptions.compareMetrics
              : []
          }
          defaultIncludeOtherDates={
            pagePresetChanges &&
            pagePresetChanges.globalOptions &&
            pagePresetChanges.globalOptions &&
            pagePresetChanges.globalOptions.compareMetricsIncludeOtherDates
              ? pagePresetChanges.globalOptions.compareMetricsIncludeOtherDates
              : false
          }
          dateIncrementOptions={R.map(option => DATE_GROUPING_MAP[option], dateIncrementOptions)}
          defaultDateGroupingKey={
            pagePresetChanges && pagePresetChanges.dateInfo && pagePresetChanges.dateInfo.increments
              ? DATE_GROUPING_MAP[pagePresetChanges.dateInfo.increments]
              : undefined
          }
          metricOptions={R.map(
            key => ({
              label: R.defaultTo(sparkChartMetricsMap[key], sparkChartMetricPrettyNameMap[key]),
              value: key as any,
            }),
            R.keys(sparkChartMetricsMap)
          )}
        />
      </div>
    );

    ////////////////////////////
    // METRICS TAB
    ////////////////////////////
    // Check to make sure nothing is missing from the table preset
    const customSegmentColumnMap = useMemo(() => {
      if (!R.isNil(filterData) && !R.isEmpty(filterData)) {
        const customSegments = filterData
          .filter(option => option.isCustomSegment)
          .reduce(
            (curr, prev) => ({
              ...curr,
              [prev.label]: makeColumnMetaData(prev.label as any),
            }),
            {}
          );
        return customSegments;
      }
    }, [filterData]);

    const processedTablePreset: MetricsTablePreset = useMemo(() => {
      const dimensionColumns: DimensionColumn[] = [];
      const dataColumns: Column[] = [];
      if (pagePreset && tablePreset && customSegmentColumnMap) {
        R.map(oldDimCol => {
          const newDimCol: DimensionColumn = {
            ...oldDimCol,
            label:
              oldDimCol && oldDimCol.label
                ? oldDimCol.label
                : { ...R.defaultTo({}, customSegmentColumnMap), ...dimensionColumnMetaDataMap }[
                    oldDimCol.dimensionTypeName
                  ].displayName,
            role: oldDimCol.role ? oldDimCol.role : 10,
          };
          dimensionColumns.push(newDimCol);
          return oldDimCol;
        }, tablePreset.dimensionColumns);
        R.map(oldDataCol => {
          const newDataCol: Column = {
            ...oldDataCol,
            label: oldDataCol.label
              ? oldDataCol.label
              : columnMetaDataMap[oldDataCol.dataVarName].displayName,
            role: oldDataCol.role ? oldDataCol.role : 10,
            kpi: oldDataCol.kpi ? oldDataCol.kpi : fetchKPI,
            lag: oldDataCol.lag ? oldDataCol.lag : fetchLag,
            audience: oldDataCol.audience ? oldDataCol.audience : fetchAudience,
          };
          dataColumns.push(newDataCol);
          return oldDataCol;
        }, tablePreset.dataColumns);
        const processedTablePreset = {
          ...tablePreset,
          dataColumns,
          dimensionColumns,
          globalOptions: {
            ...pagePreset.globalOptions,
            kpi: fetchKPI,
            lag: fetchLag,
            audience: fetchAudience,
          },
        } as any;
        return processedTablePreset;
      }
    }, [
      columnMetaDataMap,
      customSegmentColumnMap,
      dimensionColumnMetaDataMap,
      fetchAudience,
      fetchKPI,
      fetchLag,
      pagePreset,
      tablePreset,
    ]);

    // Page Modals
    const editPagePresetModal = (showEditPagePresetModal || showNewPagePresetModal) && (
      <EditPagePresetModal
        dateIncrementOptions={dateIncrementOptions}
        deliveryDimensionMap={deliveryDimensionMap}
        deliveryMetricOptions={deliveryMetricOptions}
        filterPresets={filters}
        pagePresetChanges={pagePresetChanges}
        globalOptionsPickers={{
          audience: prefix === "linear" ? ([...L.AUDIENCES] as any[]) : undefined,
          kpi: {
            kpiNameMap,
            nameKpiMap,
            options: kpiOptions,
          },
          lag: ["audio", "display", "olv", "streaming"].includes(prefix) ? [...S.LAGS] : undefined,
        }}
        groups={pagePresetGroups}
        mediatype={prefix}
        setPagePresetChanges={setPagePresetChanges}
        saveAndApplyOnClick={presetChanges => {
          (async () => {
            if (
              !R.isNil(presetChanges) &&
              !R.isNil(presetChanges.filterInfo) &&
              !R.isNil(presetChanges.filterInfo.filterState)
            ) {
              presetChanges.filterInfo.filterState = filterState;
            }
            await savePagePresetToDB(presetChanges);
            if (
              presetChanges.temporary ||
              (presetChanges.id as any) === "new" ||
              presetChanges.id <= 0 ||
              (pagePreset && presetChanges.id === pagePreset.id)
            ) {
              setDates(undefined as any);
              setOtherDates(undefined);
            }
            setShowEditPagePresetModal(false);
            setShowNewPagePresetModal(false);
          })();
        }}
        sparkChartOptions={R.keys(sparkChartMetricsMap) as Metric[]}
        tablePresets={R.filter(tablePreset => !tablePreset.temporary, tablePresets)}
        onHide={() => {
          setShowEditPagePresetModal(false);
          setShowNewPagePresetModal(false);
          if (pagePreset) {
            setPagePresetChanges(pagePreset);
          }
        }}
      />
    );

    const renamePagePresetModal = showRenamePagePresetModal && (
      <RenameModal
        applyOnClick={newName => {
          (async () => {
            await savePagePreset({
              company: company,
              id: pagePresetChanges.id,
              mediatype: prefix,
              name: newName,
              group: pagePresetChanges.presetGroup,
            });
            setShowRenamePagePresetModal(false);
            setURL(newName, tablePresetChanges?.name);
          })();
        }}
        applyText={"Save"}
        cancelText={"Discard"}
        onCancel={() => {
          setShowRenamePagePresetModal(false);
          if (pagePreset) {
            setPagePresetChanges(pagePreset);
          }
        }}
        onHide={() => {
          setShowRenamePagePresetModal(false);
          if (pagePreset) {
            setPagePresetChanges(pagePreset);
          }
        }}
        placeholder="Enter new preset name"
        subTitle={"New Page Preset Name"}
        title={`Rename ${
          pagePresetChanges && pagePresetChanges.name ? pagePresetChanges.name : ""
        }`}
      />
    );

    // Table Modals
    const defaultTablePresetNames = R.map(R.toLower, R.keys(deliveryDimensionMap));
    const editTablePresetModal = (showEditTablePresetModal || showNewTablePresetModal) && (
      <EditTablePresetModal
        columnMetaDataMap={columnMetaDataMap}
        defaultPresetNames={defaultTablePresetNames}
        dimensionColumnMetaDataMap={{
          ...R.defaultTo({}, customSegmentColumnMap),
          ...dimensionColumnMetaDataMap,
        }}
        kpiMetaData={
          (R.isNil(kpiMetaData) || (R.isEmpty(kpiMetaData) && kpiData && kpiData.kpiMap)
            ? kpiData.kpiMap
            : kpiMetaData) as any
        }
        prefix={prefix}
        onHide={() => {
          setShowEditTablePresetModal(false);
          setShowNewTablePresetModal(false);
          if (tablePreset) {
            setTablePresetChanges(tablePreset);
          }
        }}
        presetChanges={tablePresetChanges}
        setPresetChanges={setTablePresetChanges}
        saveAndApplyOnClick={tablePreset => {
          (async () => {
            const newID = await saveTablePreset({
              company,
              companyToUseInDB: globalBrand || company,
              id: !R.isNil(tablePreset.id) && tablePreset.id > 0 ? tablePreset.id : "new",
              name: tablePreset.name,
              mediatype: prefix,
              preset: tablePreset,
              temporary: tablePreset.temporary,
            });
            setTablePresetMap(`${newID.id}`, {
              ...tablePresetChanges,
              ...tablePreset,
              name: newID.name,
              id: newID.id,
              temporary: tablePreset.temporary,
            });
            if (
              pagePreset &&
              ((tablePreset.id as any) === "new" ||
                tablePreset.id === tablePresetID ||
                tablePreset.temporary)
            ) {
              const tablePresetName =
                tablePreset.temporary &&
                (R.isNil(tablePreset.id) ||
                  (tablePreset.id as any) === "new" ||
                  tablePreset.id <= 0)
                  ? newID.name
                  : tablePreset.name;
              setURL(pagePreset.name, tablePresetName);
              setDataMap(fetchKey, undefined);
              setDataStatusMap(fetchKey, undefined);
            }
            setShowEditTablePresetModal(false);
            setShowNewTablePresetModal(false);
          })();
        }}
      />
    );
    const renameTablePresetModal = showRenameTablePresetModal && (
      <RenameModal
        applyOnClick={newName => {
          (async () => {
            await saveTablePreset({
              company: company,
              id: tablePresetChanges.id,
              mediatype: prefix,
              name: newName,
              temporary: R.defaultTo(false, tablePresetChanges.temporary),
            });
            const presetInMap = tablePresetMap[`${tablePresetChanges.id}`];
            if (presetInMap) {
              setTablePresetMap(`${tablePresetChanges.id}`, {
                ...presetInMap,
                name: newName,
              });
            }
            if (tablePresetChanges.id === tablePresetID) {
              setTablePresetChanges({ ...tablePresetChanges, name: newName });
            }
            setShowRenameTablePresetModal(false);
            if (pagePreset) {
              setURL(pagePreset.name, newName);
            }
          })();
        }}
        applyText={"Save"}
        cancelText={"Discard"}
        onCancel={() => {
          setShowRenameTablePresetModal(false);
          if (tablePreset) {
            setTablePresetChanges(tablePreset);
          }
        }}
        onHide={() => {
          setShowRenameTablePresetModal(false);
          if (tablePreset) {
            setTablePresetChanges(tablePreset);
          }
        }}
        placeholder="Enter new preset name"
        subTitle={"New Table Preset Name"}
        title={`Rename ${
          tablePresetChanges && tablePresetChanges.name ? tablePresetChanges.name : ""
        }`}
      />
    );

    // Table Sizing
    const windowResizeFunction = () => setRerenderTable(true);
    if (window && window.onresize !== windowResizeFunction) {
      window.onresize = windowResizeFunction;
    }
    useLayoutEffect(() => {
      const newTableHeight = containerRef.current
        ? (containerRef.current as any).offsetHeight
        : undefined;
      const newTableWidth = containerRef.current
        ? (containerRef.current as any).offsetWidth
        : undefined;
      if (newTableHeight) {
        setTableHeight((containerRef.current as any).offsetHeight);
      }
      if (newTableWidth) {
        setTableWidth((containerRef.current as any).offsetWidth);
      }
      if (rerenderTable) {
        setRerenderTable(false);
      }
    }, [rerenderTable]);

    const metricsTable = useMemo(() => {
      if (data && !rerenderTable && tablePreset && processedTablePreset && customSegmentColumnMap) {
        return (
          <MetricsTable
            columnMetaDataMap={{
              ...customSegmentColumnMap,
              ...columnMetaDataMap,
              ...dimensionColumnMetaDataMap,
            }}
            data={R.defaultTo([], data.data) as any}
            dataFetchKey={dataFetchKey}
            exportedData={exportedData}
            filter={filter ? filter.filter : undefined}
            getDimensionCell={getDimensionCell}
            height={tableHeight}
            percentAOIPlaceholder={percentAOIPlaceholder}
            preset={processedTablePreset as any}
            setExportedData={exportedData => {
              setExportedData(exportedData);
            }}
            width={tableWidth}
          />
        );
      }
    }, [
      data,
      rerenderTable,
      tablePreset,
      processedTablePreset,
      columnMetaDataMap,
      dimensionColumnMetaDataMap,
      customSegmentColumnMap,
      dataFetchKey,
      exportedData,
      filter,
      getDimensionCell,
      tableHeight,
      percentAOIPlaceholder,
      tableWidth,
    ]);

    const metricsTableBody = (
      <div className="performance metricsTable" id="performanceContainer" ref={containerRef}>
        {pagePreset &&
        (companyInfo.streaming_performance_default_kpi || pickerKPI) &&
        data &&
        customSegmentColumnMap ? (
          metricsTable
        ) : (
          <PerformanceGridSkeleton />
        )}
      </div>
    );

    const tableFilterBar = (
      <div className="filterBarContainer">
        <FilterBar
          hasAdvanced={false}
          onFilter={filter => {
            setFilter({ filter });
          }}
          options={
            processedTablePreset && processedTablePreset.dimensionColumns
              ? R.map(col => col.dimensionTypeName, processedTablePreset.dimensionColumns)
              : []
          }
          size="lg"
          width={770}
        />
      </div>
    );
    const duplicateTablePreset = useCallback(
      preset => {
        (async () => {
          const fullyLoadedPreset =
            preset && !R.isNil(preset.id) && tablePresetMap[`${preset.id}`]
              ? tablePresetMap[`${preset.id}`]
              : await getTablePreset({ id: preset.id, company, mediatype: prefix });
          if (!tablePresetMap[`${preset.id}`] && fullyLoadedPreset) {
            setTablePresetMap(`${preset.id}`, fullyLoadedPreset);
          }
          saveTablePreset({
            company,
            id: "new",
            mediatype: prefix,
            preset: fullyLoadedPreset,
            name: `${preset.name} (Copy)`,
            temporary: R.defaultTo(false, (fullyLoadedPreset as MetricsTablePreset).temporary),
          });
        })();
      },
      [company, getTablePreset, prefix, saveTablePreset, setTablePresetMap, tablePresetMap]
    );

    const tablePresetPicker = (
      <PresetDropdown
        addPresetOnClick={() => {
          setTablePresetChanges(
            changes => ({ ...changes, id: "new", name: "My New Table Preset" } as any)
          );
          setShowNewTablePresetModal(true);
        }}
        addPresetText={"Add New Table Preset"}
        applyOnClick={preset => {
          if (pagePreset) {
            setURL(pagePreset.name, preset.name);
          }
        }}
        cancelOnClick={() => {}}
        className={`tablePresetPicker ${fetchingTablePresets ? "loading" : ""}`}
        defaultPresetNames={defaultTablePresetNames}
        deleteItemOnClick={presetID => deleteTablePreset({ id: presetID, mediatype: prefix })}
        duplicateItemOnClick={preset => duplicateTablePreset(preset)}
        editItemOnClick={preset => {
          (async () => {
            const fetchedTablePreset = (tablePresetMap[`${preset.id}`]
              ? tablePresetMap[`${preset.id}`]
              : await getTablePreset({
                  id: preset.id,
                  company,
                  mediatype: prefix,
                })) as MetricsTablePreset;
            if (!R.isNil(tablePresetMap[`${preset.id}`])) {
              setTablePresetMap(`${preset.id}`, fetchedTablePreset);
            }
            setTablePresetChanges(fetchedTablePreset);
            setShowEditTablePresetModal(true);
          })();
        }}
        hasTicks={true}
        label={
          tablePreset && !R.isNil(tablePreset.id)
            ? `Table Preset: ${tablePreset.temporary ? "Temp" : tablePreset.name}`
            : "Load Table Preset"
        }
        presets={R.filter(
          preset => !preset.temporary || (!R.isNil(tablePreset) && preset.id === tablePreset.id),
          tablePresets
        )}
        renameItemOnClick={presetID => {
          setTablePresetChanges({ id: presetID } as any);
          setShowRenameTablePresetModal(true);
        }}
        selectedPreset={tablePreset}
      />
    );

    const colorScaleItems: { label: string; style }[] = [
      { label: "", style: { backgroundColor: "#CBD2E1" } },
      { label: "", style: { backgroundColor: "#E1E6EF" } },
      { label: "", style: { backgroundColor: "#F1F3F9" } },
      { label: "Avg", style: { backgroundColor: "#FFFFFF", outline: "1px solid #BEEB80" } },
      { label: "", style: { backgroundColor: "#CBEF99" } },
      { label: "", style: { backgroundColor: "#B1E666" } },
      { label: "", style: { backgroundColor: "#7ED600" } },
    ];

    const hasDivergingColorScheme = useMemo(() => {
      if (tablePreset && tablePreset.dataColumns && tablePreset.dataColumns.length) {
        for (const col of tablePreset.dataColumns) {
          if (col && col.heatMapping && col.heatMapping.colorScheme === "diverging") {
            return true;
          }
        }
      }
      return false;
    }, [tablePreset]);

    const colorScale =
      hasDivergingColorScheme &&
      (showColorScale ? (
        <Button
          className="colorScaleButton"
          design="secondary"
          onClick={() => setShowColorScale(false)}
          size={"lg"}
          type={ButtonType.OUTLINED}
          variant={ButtonFrameworkVariant.NO_ICON}
        >
          <div className="colorScale">
            {R.map(
              item => (
                <div style={item.style}>{item.label}</div>
              ),
              colorScaleItems
            )}
          </div>
        </Button>
      ) : (
        <Button
          className="showColorScaleButton"
          design="secondary"
          icon={<ColorScale />}
          onClick={() => setShowColorScale(true)}
          size={"lg"}
          type={ButtonType.OUTLINED}
          variant={ButtonFrameworkVariant.LEADING_ICON}
        >
          See Color Scale
        </Button>
      ));
    const glossaryButton = (
      <Button
        className="glossaryButton"
        design="secondary"
        icon={<MdViewHeadline />}
        onClick={() => setShowGlossary(true)}
        size={"lg"}
        type={ButtonType.FILLED}
        variant={ButtonFrameworkVariant.LEADING_ICON}
      >
        Glossary
      </Button>
    );
    const downloadButton = (
      <div
        onClick={() => {
          const csvContent = R.map(
            e =>
              e.map(cell => (typeof cell === "number" ? cell : cell.replace(/,/g, ""))).join(","),
            exportedData
          ).join("\n");
          download(csvContent, "Metrics Table.csv", "text/csv");
        }}
      >
        <DownloadToggle />
      </div>
    );

    const glossaryModal = showGlossary && (
      <Modal
        show
        centered
        size="lg"
        onHide={() => {
          setFilteredGlossary(glossary);
          setShowGlossary(false);
        }}
        className="metricsGlossaryContainer"
      >
        <Modal.Header closeButton>
          <Modal.Title>Metrics Glossary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="glossaryFilterContainer">
            <FilterBar
              className="glossaryFilter"
              onFilter={
                ((filter: (line: GlossaryItem) => boolean) => {
                  setFilteredGlossary(R.filter(filter, glossary));
                }) as any
              }
              hasAdvanced={false}
              options={["term", "definition"]}
              variant="Dropdown"
            />
          </div>
          <div className="glossaryContent">
            {R.sortBy(elem => elem.term, filteredGlossary).map(({ term, definition }) => (
              <div key={term} className="section">
                <div className="term">{term}</div>
                <div className="definition">{definition}</div>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    );

    const metricsTableControls = mediaTypeEnabled && (
      <div className="metricsTableControlsContainer">
        {tableFilterBar}
        <div className="metricsTableControls">
          {tablePresetPicker}
          {colorScale}
          {glossaryButton}
          {downloadButton}
        </div>
      </div>
    );

    ////////////////////////////
    // DELIVERY TAB
    ////////////////////////////
    const delivery = deliveryData &&
      pagePresetChanges &&
      ((otherDates && otherDeliveryData) || !R.isNil(otherDates)) && (
        <Delivery
          company={company}
          data={deliveryData}
          dates={dates}
          defaultDateGroupingKey={
            pagePresetChanges && pagePresetChanges.dateInfo && pagePresetChanges.dateInfo.increments
              ? DATE_GROUPING_MAP[pagePresetChanges.dateInfo.increments]
              : undefined
          }
          defaultMetric={pagePresetChanges.globalOptions.deliveryMetric}
          deliveryMetricOptions={deliveryMetricOptions}
          dimension={deliveryDimension}
          dimensionOptions={R.keys(deliveryDimensionMap)}
          otherData={otherDeliveryData}
          otherDates={otherDates}
          setDimension={setDeliveryDimension}
        />
      );

    const title =
      prefix === SEARCHSHOPPING
        ? "Search & Shopping"
        : prefix === YOUTUBE
        ? "YouTube"
        : `${prefix.charAt(0).toUpperCase()}${prefix.substring(1)}`;
    return (
      <PerformanceContext.Provider
        value={{
          branchBuild,
          globalBrand: globalBrand,
          globalKpi: fetchKPI,
          globalLag: fetchLag,
          impsBetween: true,
          isGraph,
          kpiMetaData,
          prefix: prefix === "linear" ? "tv" : prefix,
        }}
      >
        <Page
          actions={actionsContainer}
          app2Redesign
          minWidth="500px"
          navs={NAVS}
          onNav={nav => {
            setSelectedTab(nav);
            if (selectedTab !== nav && nav === TabKey.METRICS_TABLE) {
              setRerenderTable(true);
            }
            if (pagePresetName) {
              const tablePresetName = tablePreset ? tablePreset.name : undefined;
              setURL(pagePresetName, tablePresetName, nav);
            }
          }}
          pageType={title}
          selectedNav={selectedTab}
          subHeader={subHeader}
          title={<div className="performanceTitle">{title}</div>}
        >
          {/* Modals */}
          {glossaryModal}
          {editPagePresetModal}
          {renamePagePresetModal}
          {editTablePresetModal}
          {renameTablePresetModal}
          {!mediaTypeEnabled ? (
            <div className="notAllowed">This company does not support this media type.</div>
          ) : (
            <>
              {/* Overview */}
              {selectedTab === TabKey.OVERVIEW && overviewSparkCharts}
              {selectedTab === TabKey.OVERVIEW && overviewCompareMetricsChart}
              {loading && selectedTab === TabKey.OVERVIEW && <Spinner size={100} />}
              {/* Metrics Table */}
              {selectedTab === TabKey.METRICS_TABLE && metricsTableControls}
              {selectedTab === TabKey.METRICS_TABLE && metricsTableBody}
              {loading && selectedTab === TabKey.METRICS_TABLE && <PerformanceGridSkeleton />}
              {/* Delivery */}
              {selectedTab === TabKey.DELIVERY && delivery}
              {loading && selectedTab === TabKey.DELIVERY && <Spinner size={100} />}
              {/* Save */}
              {saving && <FullPageSpinner transparent fixed />}
            </>
          )}
        </Page>
      </PerformanceContext.Provider>
    );
  }
);

export default SingleChannelPage;
