import React, { useMemo, useState, useCallback, useEffect, useContext } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import { download } from "../utils/download-utils";

import Select from "react-select";
import { ListGroup, Button, Modal, Form, InputGroup } from "react-bootstrap";
import { MdAdd, MdClear, MdClose, MdDelete, MdCheck } from "react-icons/md";

import { useSetError, useSetAreYouSure } from "../redux/modals";

import { CreativeLambdaFetch, pollS3, S3Put } from "../utils/fetch-utils";
import useLocation from "../utils/hooks/useLocation";
import { useMap } from "../utils/hooks/useData";
import { useExperimentFlag } from "../utils/experiments/experiment-utils";

import { FullPageSpinner, SingleDatePicker, Video, Audio, Img, Spinner } from "../Components";

import { useReportingCreative } from "./CreativeMap";
import {
  getCreativeAsset,
  getCreativeThumbnail,
} from "../SingleChannel/MetricsTable/metricsTableUtils";

export const MENU_KEY = "menu";
export const TIMELINE_KEY = "timeline";

const EditableContext = React.createContext();

const DATE_FORMAT = "yyyy-MM-dd";
const PRETTY_DATE_FORMAT = "M/d/yyyy";

const TODAY = Dfns.format(DATE_FORMAT, new Date());

const toPrettyDate = R.pipe(Dfns.parse(new Date(), DATE_FORMAT), Dfns.format(PRETTY_DATE_FORMAT));

const sameRange = R.curry(
  (a, b) => a.startDate === b.startDate && ((!a.endDate && !b.endDate) || a.endDate === b.endDate)
);

const ModalDateStateBox = ({ isLive, start, end, newDateInputs, onChange, onSave }) => {
  let startPart = start && (
    <div>
      {isLive ? "S" : "Res"}tart{isLive ? "ed" : "ing"} {toPrettyDate(start)}
    </div>
  );
  let endPart = end && (
    <div>
      End{isLive ? "ing" : "ed"} {toPrettyDate(end)}
    </div>
  );

  let part1;
  let part2;
  // First off, if we've never had any dates, we need to show that, and still have the option to add a date range. If
  // we're offline, but have a start date (we're starting in the future) and an end date (we were previously live,
  // meaning we're restarting), then we want the "Ended" to come before the "Restarting". Otherwise we always want start
  // first.
  if (!(start || end)) {
    part1 = "Never Live";
  } else if (!isLive && start && end) {
    [part1, part2] = [endPart, startPart];
  } else {
    [part1, part2] = [startPart, endPart];
  }

  return (
    <>
      <div className="dateState">
        {part1}
        {part2}
      </div>
      {!(isLive && start && !end) && (
        <>
          <div className="addRangeLabel">Add a live period from</div>
          <div className="dateRow">
            <SingleDatePicker
              showClearDate
              small
              placeholder="Start"
              date={newDateInputs.startDate}
              onChange={startDate => onChange(inputs => ({ ...inputs, startDate }))}
            />
            <div className="label">through</div>
            <SingleDatePicker
              showClearDate
              small
              placeholder="Forever"
              date={newDateInputs.endDate}
              onChange={endDate => onChange(inputs => ({ ...inputs, endDate }))}
            />
            <Button
              disabled={!newDateInputs.startDate}
              size="sm"
              onClick={() => onSave(newDateInputs)}
            >
              <MdAdd />
            </Button>
            {(newDateInputs.startDate || newDateInputs.endDate) && (
              <Button
                size="sm"
                variant="outline-danger"
                onClick={() => {
                  onChange({});
                }}
              >
                <MdClear />
              </Button>
            )}
          </div>
        </>
      )}
    </>
  );
};

const ModalDateEditBox = ({ selectedRange, onDelete, onDiscard, onSave }) => {
  const [dates, setDates] = useState(selectedRange);
  useEffect(() => {
    setDates(selectedRange);
  }, [selectedRange]);

  const hasChanges = useMemo(() => !sameRange(dates, selectedRange), [dates, selectedRange]);
  return (
    <>
      <div className="dateRow">
        <SingleDatePicker
          small
          placeholder="Start"
          date={dates.startDate}
          onChange={startDate => setDates(inputs => ({ ...inputs, startDate }))}
        />
        <div className="label">through</div>
        <SingleDatePicker
          showClearDate
          small
          placeholder="Forever"
          date={dates.endDate}
          onChange={endDate => setDates(inputs => ({ ...inputs, endDate }))}
        />
      </div>
      <div className="dateRow">
        <Button variant="danger" size="sm" onClick={onDelete}>
          Delete
        </Button>
        <Button variant="outline-dark" size="sm" onClick={onDiscard}>
          Discard
        </Button>
        <Button disabled={!hasChanges} size="sm" onClick={() => onSave(dates)}>
          Save
        </Button>
      </div>
    </>
  );
};

// U+00A0 is &nbsp;
const EditableMetaData = ({ field, defaultValue, placeholder = "\u00A0" }) => {
  const { editMap, editModeMap, setEdit, setEditMode } = useContext(EditableContext);
  let value = R.isNil(editMap[field]) ? defaultValue : editMap[field];
  return editModeMap[field] ? (
    <InputGroup size="sm">
      <Form.Control
        value={value || ""}
        onChange={e => setEdit(field, e.target.value)}
        onBlur={e => setEdit(field, (e.target.value || "").trim())}
      />
      <InputGroup.Append>
        <Button variant="outline-primary" onClick={() => setEditMode(field, false)}>
          <MdCheck />
        </Button>
      </InputGroup.Append>
    </InputGroup>
  ) : (
    <div className="editable" onClick={() => setEditMode(field, true)}>
      {value || placeholder}
    </div>
  );
};

export const ViewCreativeModal = ({
  onClose,
  creative,
  refetchData,
  selectedMediaTypes,
  companyMediaTypes,
  showRetired,
  creativeOptions,
}) => {
  const setError = useSetError();
  const setAreYouSure = useSetAreYouSure(true);

  const { company } = useLocation();

  const usesReportingCreative = useReportingCreative();

  const shouldEnableInsightsCategories = useExperimentFlag("enableInsightsCategories");

  const {
    isci,
    length,
    dimensions,
    name,
    parent,
    timeline,
    language,
    avail,
    file,
    clickthroughUrl,
    reporting,
    media_types,
    creativeTags,
    insightsCategories,
    concept,
    b2bCreatives,
    suppress_tag_alerts,
  } = creative || {};

  const [checkedAttributeOptions, setCheckedAttributeOptions] = useState({});
  const [selectedConcept, setSelectedConcept] = useState({});
  const [selectedInsightsCategories, setSelectedInsightsCategories] = useState([]);
  const [suppressTagAlerts, setSuppressTagAlerts] = useState(suppress_tag_alerts);

  let defaultConcept;
  if (concept) {
    defaultConcept = { value: concept, label: concept };
  }

  let conceptNaMap = {};
  for (let concept of creativeOptions.conceptData) {
    conceptNaMap[concept.concept] = concept.categories;
  }

  let conceptDropdownOptions = [];
  if (creativeOptions.conceptData.length > 0) {
    for (let concept of creativeOptions.conceptData) {
      conceptDropdownOptions.push({
        value: concept.concept,
        label: concept.concept,
        id: concept.id,
      });
    }
  }

  useEffect(() => {
    let initialCheckedAttributes = {};
    for (let key of R.keys(creativeTags)) {
      for (let val of creativeTags[key]) {
        if (initialCheckedAttributes[key]) {
          initialCheckedAttributes[key][val] = true;
        } else {
          initialCheckedAttributes[key] = { [val]: true };
        }
      }
    }
    setCheckedAttributeOptions(initialCheckedAttributes);

    setSelectedInsightsCategories(insightsCategories || []);
  }, [creativeTags, insightsCategories, setCheckedAttributeOptions]);

  const deselectNaAttributes = concept => {
    let deselectMap = {};

    for (let attribute of R.keys(conceptNaMap[concept])) {
      if (R.path([concept, attribute], conceptNaMap) === false) {
        for (let attributeObj of creativeOptions.categoryData) {
          if (attributeObj.name === attribute) {
            for (let option of attributeObj.options) {
              deselectMap[attribute] = { ...(deselectMap[attribute] || {}), [option]: false };
            }
          }
        }
      }
    }

    setCheckedAttributeOptions(current => {
      return { ...current, ...deselectMap };
    });
  };

  const [newDateRanges, setNewDateRanges] = useState([]);
  const [deletions, setDeletions] = useState([]);

  const [newDateInputs, setNewDateInputs] = useState({});

  const [editModeMap, setEditMode] = useMap();
  const [editMap, setEdit] = useMap();

  const hasChanges = useMemo(
    () =>
      newDateRanges.length ||
      deletions.length ||
      newDateInputs.startDate ||
      newDateInputs.endDate ||
      R.keys(editMap).length ||
      !R.isEmpty(checkedAttributeOptions) ||
      !R.isEmpty(selectedConcept) ||
      !R.isEmpty(selectedInsightsCategories),
    [
      newDateRanges.length,
      deletions.length,
      newDateInputs.startDate,
      newDateInputs.endDate,
      editMap,
      checkedAttributeOptions,
      selectedConcept,
      selectedInsightsCategories,
    ]
  );

  const deleteItem = useCallback(
    item => {
      for (let i = 0; i < newDateRanges.length; ++i) {
        let range = newDateRanges[i];
        if (sameRange(range, item)) {
          setNewDateRanges(ranges => R.remove(i, 1, ranges));
          return;
        }
      }
      setDeletions(deletions => [...deletions, item]);
    },
    [newDateRanges]
  );

  const filteredItems = useMemo(
    () =>
      R.pipe(
        R.path([
          selectedMediaTypes.linear || (selectedMediaTypes.audio && avail === "L")
            ? "linear"
            : "streaming",
          "ranges",
        ]),
        R.concat(newDateRanges),
        R.filter(item => R.none(sameRange(item), deletions)),
        R.sort(R.descend(R.prop("startDate")))
      )(timeline),
    [selectedMediaTypes.linear, selectedMediaTypes.audio, avail, newDateRanges, timeline, deletions]
  );

  const [isLive, start, end] = useMemo(() => {
    let start, end;
    for (let { startDate, endDate } of filteredItems) {
      if (startDate <= TODAY && (!endDate || endDate >= TODAY)) {
        return [true, startDate, endDate];
      }
      if (endDate < TODAY) {
        end = R.max(endDate, end);
      }
      if (startDate > TODAY) {
        start = R.min(startDate, start);
      }
    }
    return [false, start, end];
  }, [filteredItems]);

  const [selectedRange, setSelectedRange] = useState();

  const addItem = useCallback(
    (item, oldVal) => {
      const dateText = ({ startDate, endDate }) =>
        `${toPrettyDate(startDate)} -> ${endDate ? toPrettyDate(endDate) : "Forever"}`;

      // Make sure the range starts before it ends
      if (item.startDate > item.endDate) {
        setError({
          title: "Invalid Date Range",
          message: `Your start date must be before your end date. Your date range was:\n${dateText(
            item
          )}`,
        });
        return false;
      }

      // Validate our new input against all our existing items. Note that filteredItems combines pending deletions and
      // new ranges.
      for (let filteredItem of filteredItems) {
        // Don't validate against the item we're replacing
        if (oldVal && sameRange(filteredItem, oldVal)) {
          continue;
        }
        // Make sure our item doesn't overlap with this item. If either has an undefined end date, that's the equivalent
        // of "forever", so that date will always be >= to another date (so we can short-circuit with the ||).
        if (
          (!filteredItem.endDate || filteredItem.endDate >= item.startDate) &&
          (!item.endDate || filteredItem.startDate <= item.endDate)
        ) {
          setError({
            title: "Overlapping Dates",
            message: `The dates you entered:\n${dateText(
              item
            )}\noverlap with another date range on this creative:\n${dateText(filteredItem)}`,
          });
          return false;
        }
      }

      // If we have an oldVal, we need to delete it.
      if (oldVal) {
        deleteItem(oldVal);
      }

      // The item we're adding could be something they've already deleted in this session. Check all the deletions. If
      // any of them match, just remove it from the deletion list to get the same effect.
      let restoredDeleted = false;
      for (let i = 0; i < deletions.length; ++i) {
        let deletion = deletions[i];
        if (sameRange(deletion, item)) {
          setDeletions(R.remove(i, 1));
          restoredDeleted = true;
          break;
        }
      }

      if (!restoredDeleted) {
        setNewDateRanges(R.append(item));
      }

      return true;
    },
    [filteredItems, deletions, setError, deleteItem]
  );

  const dateEditBoxChangeHandlers = useMemo(
    () => ({
      onSave: item => {
        if (addItem(item, selectedRange)) {
          setSelectedRange();
        }
      },
      onDelete: () => {
        deleteItem(selectedRange);
        setSelectedRange();
      },
      onDiscard: () => setSelectedRange(),
    }),
    [selectedRange, deleteItem, addItem]
  );

  const onDateStateSave = useCallback(() => {
    let success;
    success = addItem(newDateInputs);
    if (success) {
      setNewDateInputs({});
    }
    return success;
  }, [addItem, newDateInputs]);

  const [saving, setSaving] = useState(false);

  const [saveChanges, setSaveChanges] = useState(false);

  useEffect(() => {
    if (saveChanges) {
      setSaveChanges(false);
      if (selectedRange) {
        setError({
          title: "Currently Editing",
          message:
            "You're currently editing a date range. Please either save it or discard your changes.",
        });
        return;
      }
      if (editMap.name === "") {
        setError({
          title: "No Name",
          message: "You cannot have a blank creative name.",
        });
        return;
      }
      if (editMap.clickthrough && !R.test(/^https:\/\//, editMap.clickthrough)) {
        setError({
          title: "Click-through HTTPS",
          message: `Click-through URLs must begin with "https://". You entered "${editMap.clickthrough}"`,
        });
        return;
      }
      (async () => {
        let body = {
          company,
          newDates: newDateRanges.map(range => ({
            isci,
            linear: selectedMediaTypes.linear || (selectedMediaTypes.audio && avail === "L"),
            ...range,
          })),
          deletedDates: deletions.map(range => ({
            isci,
            linear: selectedMediaTypes.linear || (selectedMediaTypes.audio && avail === "L"),
            ...range,
          })),
          changes: R.keys(editMap).length ? [{ isci, ...editMap }] : [],
          checkedAttributeOptions,
          selectedConcept,
          isci,
        };

        try {
          setSaving(true);
          await CreativeLambdaFetch("/", {
            method: "post",
            body,
          });
        } catch (e) {
          // TODO: if we ever change the lambda fetchers and/or awaitJSON to properly give us status codes, I want to use
          // that here to check for the 409
          if ((e.message || "").includes("The following creative name is already taken")) {
            try {
              await setAreYouSure({
                title: "Creative Already Defined",
                message: `${e.message} The ISCIs you're currently editing might be grouped together with existing creatives on certain pages. Are you sure you want to continue?`,
                okayText: "Add Them Anyway",
              });
            } catch (e) {
              setSaving(false);
              return;
            }
            try {
              await CreativeLambdaFetch("/", {
                method: "post",
                body: {
                  ...body,
                  skipNameCheck: true,
                },
              });
            } catch (e) {
              let message = `Failed to edit creatives. ${e.message}`;
              setError({ message, reportError: e });
              setSaving(false);
              return;
            }
          } else {
            let message = `Couldn't save creative changes. ${e.message}`;
            setError({ message, reportError: e });
            setSaving(false);
            return;
          }
        }
        refetchData();
      })();
    }
  }, [
    saveChanges,
    isci,
    selectedMediaTypes,
    newDateRanges,
    deletions,
    setError,
    refetchData,
    selectedRange,
    company,
    editMap,
    setAreYouSure,
    checkedAttributeOptions,
    selectedConcept,
    avail,
  ]);

  const onRetire = useCallback(async () => {
    for (let type of ["streaming", "linear"]) {
      let ranges = R.path([type, "ranges"], timeline);
      for (let { startDate, endDate } of ranges) {
        // If any date range is either open ended, or ends in the future, then it will again be live one day
        if (!endDate || endDate >= TODAY) {
          setError({
            title: "Retiring live ISCI",
            variant: "warning",
            message: `You cannot retire an ISCI that is either live or set to become live in the future. This isci is live for ${type} from ${toPrettyDate(
              startDate
            )} through ${
              endDate ? toPrettyDate(endDate) : "forever"
            }.\nIf you want to retire this ISCI, mark it as not-live.`,
          });
          return;
        }
      }
    }
    try {
      await setAreYouSure({
        title: `Retire ${isci}`,
        message: `You're about to retire "${isci}". Are you sure you want to continue?`,
        okayText: "Yes",
        cancelText: "Never mind",
      });
    } catch (e) {
      return;
    }

    try {
      setSaving(true);
      await CreativeLambdaFetch("/", {
        method: "post",
        body: {
          company,
          changes: [{ isci, retired: true }],
        },
      });
      refetchData();
    } catch (e) {
      let message = `Couldn't retire ${isci}. ${e.message}`;
      setError({ message, reportError: e });
      setSaving(false);
    }
  }, [setAreYouSure, isci, setError, refetchData, company, timeline]);

  // const [parentOptions, variantOptions] = useMemo(() => {
  //   if (!preFillEditData) {
  //     return [];
  //   }
  //   return ["parents", "variants"].map(key =>
  //     R.map(value => ({ value, label: value }), preFillEditData[key])
  //   );
  // }, [preFillEditData]);

  const mediaTypeCheck = useMemo(() => {
    // If it's an audio asset, or the client doesn't have both linear and streaming, don't do anything
    if (
      media_types.includes("audio") ||
      !(companyMediaTypes.includes("streaming") && companyMediaTypes.includes("tv"))
    ) {
      return;
    }
    const hasStreaming = media_types.includes("streaming");
    const hasLinear = media_types.includes("linear");

    let content;
    if (hasStreaming && hasLinear) {
      content = <div className="typeLabel">Both streaming and Linear.</div>;
    } else if (hasStreaming) {
      content = (
        <Form.Check
          type="checkbox"
          id="addLinearViewCreativeModalCheckBox"
          label="Add Linear"
          checked={!!editMap.addLinear}
          onChange={() => setEdit("addLinear", !editMap.addLinear)}
        />
      );
    } else if (hasLinear) {
      content = (
        <Form.Check
          type="checkbox"
          id="addStreamingViewCreativeModalCheckBox"
          label="Add Streaming"
          checked={!!editMap.addStreaming}
          onChange={() => setEdit("addStreaming", !editMap.addStreaming)}
        />
      );
    }
    return (
      <div className="metaData">
        <div>
          <div>Media Types</div>
          {content}
        </div>
      </div>
    );
  }, [media_types, companyMediaTypes, editMap, setEdit]);

  const [copyPointsPdf, setCopyPointsPdf] = useState();

  useEffect(() => {
    (async () => {
      if (media_types.includes("audio")) {
        if (!copyPointsPdf) {
          try {
            const fetchedCopyPointsPdf = await pollS3({
              bucket: "bpm-cdn",
              mimeType: "application/pdf",
              filename: `creatives/${file}_CopyPoints.pdf`,
              autoDownload: false,
              timeout: 3,
            });
            setCopyPointsPdf(fetchedCopyPointsPdf || "None");
          } catch {
            setCopyPointsPdf("None");
          }
        }
      }
    })();
  }, [copyPointsPdf, file, media_types]);

  const uploadPdfToS3 = pdf => {
    const fileReader = new FileReader();

    fileReader.onload = event => {
      let data = event.target.result;

      let splitString = data.split(",");
      data = splitString[1];

      S3Put("bpm-cdn", `creatives/${file}_CopyPoints.pdf`, data, {
        bufferEncoding: "base64",
        contentType: pdf.type,
      });
    };

    fileReader.readAsDataURL(pdf);
  };

  return (
    <Modal show onHide={onClose} className="creativeMapMenuViewModal" size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{isci}</Modal.Title>
      </Modal.Header>
      <div className="creativeMenuModalBody">
        <div className="leftPane">
          <Video
            src={getCreativeAsset(company, file)}
            poster={getCreativeThumbnail(company, isci)}
            controls
            className="videoPlayer"
            unloader={<Img title={file} src={getCreativeThumbnail(company, isci)} />}
          />
          {media_types.includes("audio") && (
            <Audio controls className="audioPlayer" src={getCreativeAsset(company, file)} />
          )}
          <Modal.Body className="leftPaneBody">
            {/* We're going to have all these edit boxes that need access to two maps (which have values and setters)
                from the parent. Instead of passing 4 args to each child, this is easier. */}
            <EditableContext.Provider
              value={{
                editModeMap,
                setEditMode,
                editMap,
                setEdit,
              }}
            >
              <div className="metaData">
                <div>
                  <div>Name</div>
                  <EditableMetaData field="name" defaultValue={name} />
                </div>
                {media_types.includes("display") ? (
                  <div>
                    <div>Dimensions</div>
                    <div>{dimensions}</div>
                  </div>
                ) : (
                  <div>
                    <div>Length</div>
                    <div>{length}s</div>
                  </div>
                )}
              </div>
              <div className="metaData">
                <div>
                  <div>Language</div>
                  <div>{language}</div>
                </div>
              </div>
              {media_types.includes("audio") && (
                <div className="metaData">
                  <div>
                    <div>Copy Points</div>
                    {copyPointsPdf ? (
                      <div>
                        {copyPointsPdf !== "None" && (
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                          <a className="copyPointsPdfLink" onClick={() => download(copyPointsPdf)}>
                            Download PDF
                          </a>
                        )}
                        <div className="fileUploadWrapper">
                          <label htmlFor="fileInput" className="fileInputLabel">
                            {copyPointsPdf !== "None" ? "Change File" : "Upload File"}
                          </label>
                          <input
                            type="file"
                            id="fileInput"
                            onChange={e => {
                              const uploadedFileList = e.target.files;
                              const uploadedFile = uploadedFileList?.[0];
                              uploadPdfToS3(uploadedFile);
                              setCopyPointsPdf();
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </div>
              )}
              {selectedMediaTypes.linear && (
                <div className="metaData">
                  <div>
                    <div>Avail</div>
                    <div>{avail}</div>
                  </div>
                </div>
              )}
              {usesReportingCreative && (
                <div className="metaData">
                  <div>
                    <div>Reporting Creative</div>
                    <EditableMetaData
                      field="reporting"
                      defaultValue={reporting}
                      placeholder="None"
                    />
                  </div>
                </div>
              )}
              {usesReportingCreative && (
                <div className="metaData">
                  <div>
                    <div>Parent Creative</div>
                    <EditableMetaData field="parent" defaultValue={parent} placeholder="None" />
                  </div>
                </div>
              )}
              {(selectedMediaTypes.streaming ||
                selectedMediaTypes.audio ||
                selectedMediaTypes.display) && (
                <div className="metaData">
                  <div>
                    <div>Click-through URL</div>
                    <EditableMetaData
                      field="clickthrough"
                      defaultValue={clickthroughUrl}
                      placeholder="None"
                    />
                  </div>
                </div>
              )}
              {mediaTypeCheck}
              <div className="metaData">
                <div>
                  <div>Concept</div>
                  <Select
                    isDisabled={defaultConcept && defaultConcept.value === "B2B"}
                    className="networkSelect"
                    value={R.isEmpty(selectedConcept) ? defaultConcept : selectedConcept}
                    options={conceptDropdownOptions}
                    onChange={selection => {
                      setSelectedConcept({
                        value: selection.value,
                        label: selection.value,
                        conceptId: selection.id,
                      });
                      deselectNaAttributes(selection.value);
                    }}
                  />
                </div>
              </div>
              {shouldEnableInsightsCategories && (
                <div className="metaData">
                  <div>
                    <div>Insights Categories</div>
                    <Select
                      isMulti={true}
                      className="insightsCategoriesSelect"
                      value={selectedInsightsCategories.map(cat => {
                        return { label: cat, value: cat };
                      })}
                      options={creativeOptions.insightsCategoryData.map(cat => {
                        return { label: cat, value: cat };
                      })}
                      onChange={selection => {
                        const newValues = selection || [];
                        setSelectedInsightsCategories(newValues.map(cat => cat.value));
                        const newInsightsCategoriesObject = {};
                        newValues.forEach(cat => (newInsightsCategoriesObject[cat.value] = true));
                        setCheckedAttributeOptions(current => {
                          return {
                            ...current,
                            "Insights Category": newInsightsCategoriesObject,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
              )}
              {creativeTags && (
                <div className="metaData">
                  <div className="tagsContainer">
                    {<span className="title">Tags: </span>}
                    <div className="nameContainer">
                      {Object.keys(checkedAttributeOptions).map(attribute => {
                        return Object.keys(checkedAttributeOptions[attribute]).map(option => {
                          return (
                            <span className="tagName" key={option}>
                              {attribute}: {option}
                            </span>
                          );
                        });
                      })}
                    </div>
                  </div>
                </div>
              )}
              {b2bCreatives && (
                <div className="metaData">
                  <div className="backToBackCreatives">
                    <div className="title">Back-to-Back Creatives</div>
                    <div>{`${b2bCreatives.isci1} & ${b2bCreatives.isci2}`}</div>
                  </div>
                </div>
              )}
              {creativeOptions.categoryData.length > 0 && R.isNil(b2bCreatives) && (
                <div className="metaData">
                  <div className="creativeAttributes">
                    <div className="title">Creative Attributes</div>
                    <div className="attributeBoxesContainer">
                      {R.map(attribute => {
                        return (
                          <div className="attributeBox" key={attribute.name}>
                            <div className="name">
                              <div>{attribute.name}</div>
                            </div>
                            <div className="options">
                              {attribute.options.map(option => {
                                return (
                                  <div className="option" key={option}>
                                    <Form.Check
                                      key={option}
                                      type="checkbox"
                                      label={option}
                                      checked={R.path(
                                        [attribute.name, option],
                                        checkedAttributeOptions
                                      )}
                                      disabled={
                                        R.path(
                                          [
                                            selectedConcept.value || (defaultConcept || {}).value,
                                            attribute.name,
                                          ],
                                          conceptNaMap
                                        ) === false
                                      }
                                      onChange={e => {
                                        let { checked } = e.target;
                                        setCheckedAttributeOptions(current => {
                                          return {
                                            ...current,
                                            [attribute.name]: {
                                              ...checkedAttributeOptions[attribute.name],
                                              [option]: checked,
                                            },
                                          };
                                        });
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      }, creativeOptions.categoryData)}
                    </div>
                  </div>
                </div>
              )}
              <div className="metaData">
                <div>
                  <div>Tag Alerts</div>
                  <Form.Check
                    type="checkbox"
                    label="Suppress"
                    checked={suppressTagAlerts}
                    onChange={() => {
                      setEdit("suppressTagAlerts", !suppressTagAlerts);
                      setSuppressTagAlerts(!suppressTagAlerts);
                    }}
                  />
                </div>
              </div>
            </EditableContext.Provider>
          </Modal.Body>
        </div>
        <Modal.Body>
          <div className="newDateBox">
            {selectedRange ? (
              <ModalDateEditBox selectedRange={selectedRange} {...dateEditBoxChangeHandlers} />
            ) : (
              <ModalDateStateBox
                key={JSON.stringify(filteredItems)}
                isLive={isLive}
                start={start}
                end={end}
                newDateInputs={newDateInputs}
                onChange={setNewDateInputs}
                onSave={onDateStateSave}
              />
            )}
          </div>
          <div className="dateList">
            {filteredItems.length ? (
              <ListGroup variant="flush">
                {filteredItems.map(item => {
                  const { startDate, endDate } = item;
                  let variant;
                  if (endDate && endDate < TODAY) {
                    variant = "dark";
                  }
                  if (startDate <= TODAY && (!endDate || endDate >= TODAY)) {
                    variant = "primary";
                  }
                  let active = selectedRange && sameRange(selectedRange, item);

                  return (
                    <ListGroup.Item
                      action
                      active={active}
                      key={startDate}
                      className="dateListItem"
                      variant={variant}
                      onClick={() => setSelectedRange(active ? null : item)}
                    >
                      <div className="date">{toPrettyDate(startDate)}</div>
                      <div className="date">{endDate ? toPrettyDate(endDate) : "Forever"}</div>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            ) : (
              <div className="noDates">No Dates</div>
            )}
          </div>
        </Modal.Body>
      </div>
      <Modal.Footer className="modalControls" id={showRetired ? "showRetired" : ""}>
        {!showRetired && (
          <Button variant="danger" onClick={onRetire}>
            Retire ISCI
          </Button>
        )}
        <div className="rightButtons">
          <Button onClick={onClose} variant="dark">
            Cancel
          </Button>
          <Button
            disabled={!hasChanges || saving}
            onClick={() => {
              let success = true;
              if (newDateInputs.startDate) {
                success = onDateStateSave();
              }
              if (success) {
                setSaveChanges(true);
              }
            }}
          >
            Save
          </Button>
        </div>
      </Modal.Footer>
      {saving && (
        <div className="savingOverlay">
          <FullPageSpinner />
        </div>
      )}
    </Modal>
  );
};

const MULTIPLE_CLICKTHROUGHS = "multiple";
const NO_CLICKTHROUGHS = "none";

export const BulkEditModal = ({
  onClose,
  selectedISCIs,
  setSelectedISCIs,
  creativeData,
  refetchData,
  selectedStreaming,
  creativeAttributes,
}) => {
  const setError = useSetError();
  const setAreYouSure = useSetAreYouSure(true);

  const { company } = useLocation();

  const [newEndDate, setNewEndDate] = useState();
  const [forever, setForever] = useState(false);
  const [newDateInputs, setNewDateInputs] = useState({});
  const [saving, setSaving] = useState(false);
  const [newClickthrough, setNewClickthrough] = useState("");
  const [clickthroughChanged, setClickthroughChanged] = useState(false);

  const [checkedAttributeOptions, setCheckedAttributeOptions] = useState({});

  const hasCheckedCreativeAttributeOptions = Object.values(checkedAttributeOptions)
    .map(category => Object.values(category).includes(true))
    .reduce((a, b) => a || b, false);

  const { ourData, commonClickthrough, notesPerISCI, hasWarnings, hasProblems } = useMemo(() => {
    let ourData = [];
    let commonClickthrough;
    let notesPerISCI = {};
    let hasWarnings = false;
    let hasProblems = false;
    for (let isci of R.keys(selectedISCIs)) {
      if (selectedISCIs[isci]) {
        let creative = creativeData[isci];
        ourData.push(creative);

        if (!commonClickthrough) {
          commonClickthrough = creative.clickthroughUrl || NO_CLICKTHROUGHS;
        } else if (commonClickthrough !== MULTIPLE_CLICKTHROUGHS) {
          if (
            !(
              (!creative.clickthroughUrl && commonClickthrough === NO_CLICKTHROUGHS) ||
              creative.clickthroughUrl === commonClickthrough
            )
          ) {
            commonClickthrough = MULTIPLE_CLICKTHROUGHS;
          }
        }

        let ranges =
          R.path(["timeline", selectedStreaming ? "streaming" : "linear", "ranges"], creative) ||
          [];
        let notes = { warnings: [], problems: [] };
        for (let { startDate, endDate } of ranges) {
          let modifiedEndDate = endDate;
          if (
            forever ||
            (newEndDate && startDate <= newEndDate && (!endDate || endDate > newEndDate))
          ) {
            if (forever && !endDate) {
              delete notes.ending;
            } else {
              notes.ending = { startDate, endDate };
              modifiedEndDate = forever ? null : newEndDate;
              if (startDate > TODAY) {
                hasWarnings = true;
                notes.warnings.push(
                  `You are ending a date range that starts in the future: ${toPrettyDate(
                    startDate
                  )}.`
                );
              }
            }
          }
          if (
            newDateInputs.startDate &&
            (!modifiedEndDate || newDateInputs.startDate <= modifiedEndDate) &&
            (!newDateInputs.endDate || startDate <= newDateInputs.endDate)
          ) {
            notes.overlap = { startDate, endDate: modifiedEndDate };
            hasProblems = true;
            notes.problems.push(
              `The live range you've added with overlap with an existing live range on this ISCI: ${toPrettyDate(
                startDate
              )} - ${modifiedEndDate ? toPrettyDate(modifiedEndDate) : "Forever"}.`
            );
          }
        }
        if (R.keys(notes).length) {
          notesPerISCI[isci] = notes;
        }
      }
    }

    ourData = R.sortBy(R.prop("isci"), ourData);

    ourData = R.sortWith(
      [
        R.descend(({ isci }) => R.path([isci, "problems", "length"], notesPerISCI) > 0),
        R.descend(({ isci }) => R.path([isci, "warnings", "length"], notesPerISCI) > 0),
        R.ascend(R.prop("isci")),
      ],
      ourData
    );

    return {
      ourData,
      commonClickthrough,
      notesPerISCI,
      hasWarnings,
      hasProblems,
    };
  }, [selectedISCIs, creativeData, selectedStreaming, newEndDate, newDateInputs, forever]);

  const onRetire = useCallback(async () => {
    for (let { isci, timeline } of ourData) {
      for (let type of ["streaming", "linear"]) {
        let ranges = R.path([type, "ranges"], timeline);
        for (let { startDate, endDate } of ranges) {
          if (!endDate || endDate >= TODAY) {
            setError({
              title: "Retiring live ISCI",
              variant: "warning",
              message: `You cannot retire an ISCI that is either live or set to become live in the future. You have selected at least one such ISCI: ${isci}, live for ${type} from ${toPrettyDate(
                startDate
              )} through ${endDate ? toPrettyDate(endDate) : "forever"}.`,
            });
            return;
          }
        }
      }
    }
    try {
      await setAreYouSure({
        title: "Retire ISCIs?",
        message: "You're about to retire multiple ISCIs. Are you sure you want to continue?",
        okayText: "Yes",
        cancelText: "Never mind",
      });
    } catch (e) {
      return;
    }
    try {
      setSaving(true);
      await CreativeLambdaFetch("/", {
        method: "post",
        body: {
          company,
          changes: ourData.map(({ isci }) => ({
            isci,
            retired: true,
          })),
        },
      });
      refetchData();
    } catch (e) {
      let message = `Couldn't retire multiple ISCIs. ${e.message}`;
      setError({ message, reportError: e });
      setSaving(false);
    }
  }, [setAreYouSure, ourData, setError, refetchData, company]);

  const onSave = useCallback(async () => {
    if (hasProblems) {
      setError({
        title: "Pending errors",
        message:
          "Your changes are incompatible with the ISCIs you've selected. Fix these issues before you save.",
      });
      return;
    }

    if (newClickthrough && !R.test(/^https:\/\//, newClickthrough)) {
      setError({
        title: "Click-through HTTPS",
        message: `Click-through URLs must begin with "https://". You entered "${newClickthrough}"`,
      });
      return;
    }

    if (newDateInputs && newDateInputs.endDate && newDateInputs.endDate < newDateInputs.startDate) {
      setError({
        title: "Date range must end after it starts",
        message:
          "Your new live range ends before it begins. Make the end date after the start date (or blank).",
      });
      return;
    }

    if (hasWarnings) {
      try {
        await setAreYouSure({
          title: "Saving with warnings",
          message: "Your pending changes have warnings. Are you sure you want to continue?",
          okayText: "Yes",
          cancelText: "Never mind",
        });
      } catch (e) {
        // This means they said never mind, so we want to back out.
        return;
      }
    }
    try {
      setSaving(true);
      let newDates = [];
      let deletedDates = [];
      let changes = [];
      for (let isci of R.keys(notesPerISCI)) {
        let { ending } = notesPerISCI[isci];
        if (ending) {
          deletedDates.push({
            isci,
            linear: !selectedStreaming,
            ...ending,
          });
          newDates.push({
            isci,
            linear: !selectedStreaming,
            startDate: ending.startDate,
            endDate: newEndDate,
          });
        }
        if (clickthroughChanged) {
          changes.push({ isci, clickthrough: newClickthrough });
        }
        if (newDateInputs.startDate) {
          newDates.push({
            isci,
            linear: !selectedStreaming,
            ...newDateInputs,
          });
        }
      }

      await CreativeLambdaFetch("/", {
        method: "post",
        body: {
          company,
          newDates,
          deletedDates,
          changes,
          checkedAttributeOptions: hasCheckedCreativeAttributeOptions
            ? checkedAttributeOptions
            : null,
          iscis: Object.keys(selectedISCIs).filter(isci => !!selectedISCIs[isci]),
        },
      });
      refetchData();
    } catch (e) {
      let message = `Couldn't save creative changes. ${e.message}`;
      setError({ message, reportError: e });
    }
  }, [
    newClickthrough,
    clickthroughChanged,
    hasProblems,
    hasWarnings,
    setError,
    setAreYouSure,
    refetchData,
    notesPerISCI,
    newDateInputs,
    newEndDate,
    company,
    selectedStreaming,
    checkedAttributeOptions,
    hasCheckedCreativeAttributeOptions,
    selectedISCIs,
  ]);

  return (
    <Modal
      show
      onHide={onClose}
      className="creativeMapBulkEditModal creativeMapMenuViewModal"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Multiple ISCIs</Modal.Title>
      </Modal.Header>
      <Modal.Body className="creativeMapBulkEditBody">
        <div className="dateSetters">
          <div>
            <div>End on</div>
            <SingleDatePicker
              showClearDate
              small
              placeholder={forever ? "Forever" : "Date"}
              date={newEndDate}
              onChange={newDate => {
                setForever(false);
                setNewEndDate(newDate);
              }}
            />
            <Button size="sm" variant="outline-secondary" onClick={() => setForever(R.not)}>
              Forever
            </Button>
          </div>
          <div className="dateRange">
            <div>Add period from</div>
            <SingleDatePicker
              showClearDate
              small
              placeholder="Start"
              date={newDateInputs.startDate}
              onChange={startDate => setNewDateInputs(dates => ({ ...dates, startDate }))}
            />
            <div>through</div>
            <SingleDatePicker
              showClearDate
              small
              placeholder="Forever"
              date={newDateInputs.endDate}
              onChange={endDate => setNewDateInputs(dates => ({ ...dates, endDate }))}
            />
          </div>
        </div>
        {selectedStreaming && (
          <div className="editControlRow">
            <div className="clickthroughLabel">Click-through URL</div>
            <Form.Control
              placeholder={clickthroughChanged ? "none" : `${commonClickthrough} (unchanged)`}
              value={newClickthrough}
              onChange={e => {
                setClickthroughChanged(true);
                setNewClickthrough(e.target.value);
              }}
            />
            {clickthroughChanged && (
              <Button
                variant="outline-danger"
                onClick={() => {
                  setClickthroughChanged(false);
                  setNewClickthrough("");
                }}
              >
                <MdDelete />
              </Button>
            )}
          </div>
        )}
        <div className="metaData">
          <div className="creativeAttributes">
            <div className="title">Creative Attributes</div>
            <div className="attributeBoxesContainer">
              {R.map(attribute => {
                return (
                  <div className="attributeBox" key={attribute.name}>
                    <div className="name">
                      <div>{attribute.name}</div>
                    </div>
                    <div className="options">
                      {attribute.options.map(option => {
                        return (
                          <div className="option" key={option}>
                            <Form.Check
                              key={option}
                              type="checkbox"
                              label={option}
                              checked={R.path([attribute.name, option], checkedAttributeOptions)}
                              onChange={e => {
                                let { checked } = e.target;
                                setCheckedAttributeOptions(current => {
                                  return {
                                    ...current,
                                    [attribute.name]: {
                                      ...checkedAttributeOptions[attribute.name],
                                      [option]: checked,
                                    },
                                  };
                                });
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              }, creativeAttributes)}
            </div>
          </div>
        </div>
        <div className="creativeList">
          <ListGroup variant="flush">
            {ourData.map(({ isci }) => {
              let { ending, problems, warnings } = R.prop(isci, notesPerISCI) || {};
              let variant = "";

              if (problems.length) {
                variant = "danger";
              } else if (warnings.length) {
                variant = "warning";
              }
              return (
                <ListGroup.Item key={isci} variant={variant} className="creativeRow">
                  <div className="mainRow">
                    <div>{isci}</div>
                    {(newEndDate || forever) &&
                      (ending ? (
                        <div>
                          {toPrettyDate(ending.startDate)} -{" "}
                          <span className="strikethrough">
                            {ending.endDate ? toPrettyDate(ending.endDate) : "forever"}
                          </span>{" "}
                          {forever ? "forever" : toPrettyDate(newEndDate)}
                        </div>
                      ) : (
                        <div>Unchanged</div>
                      ))}
                    <Button
                      className="removeButton"
                      size="sm"
                      variant="outline-danger"
                      onClick={() => {
                        setSelectedISCIs(map => ({ ...map, [isci]: false }));
                      }}
                    >
                      <MdClose />
                    </Button>
                  </div>
                  {problems.length + warnings.length > 0 && (
                    <div className="problems">
                      <ul>
                        {[...problems, ...warnings].map(problem => (
                          <li key={problem}>{problem}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </div>
      </Modal.Body>
      <Modal.Footer className="modalControls">
        <Button variant="danger" onClick={onRetire}>
          Retire ISCIs
        </Button>
        <div className="rightButtons">
          <Button onClick={onClose} variant="outline-primary">
            Cancel
          </Button>
          <Button
            disabled={
              hasProblems ||
              !(
                newEndDate ||
                newDateInputs.startDate ||
                clickthroughChanged ||
                hasCheckedCreativeAttributeOptions ||
                forever
              )
            }
            onClick={onSave}
          >
            {hasProblems ? "Please first resolve errors" : "Save"}
          </Button>
        </div>
      </Modal.Footer>
      {saving && (
        <div className="savingOverlay">
          <FullPageSpinner />
        </div>
      )}
    </Modal>
  );
};
