import "./InvestToIncrease.scss";
import { useEffect, useMemo, useState } from "react";
import WidgetContainer from "../../Components/WidgetContainer";
import MoreInfo from "../../MMM/MoreInfo";
import { SnapshotChart } from "../../Components";
import * as R from "ramda";
import {
  standardizeMetricName,
  snapShotDataSort,
  keyGeneratorForChannelTacticBrandGrouping,
} from "./BrandImpactUtils";
import { currencyFormatter } from "../../MMM/MMMUtils";
import { PaidMediaDataRow } from "@blisspointmedia/bpm-types/dist/BrandEquity";

interface NextBestDollar {
  label: string;
  channel: string;
  tactic: string;
  "brand/nonbrand": string;
  value: number;
}

interface InvestToIncreaseProps {
  company: string;
  groupByMetric: string;
  groupByLevel: string;
  unGroupedSpendAndEffect: PaidMediaDataRow[];
  unGroupedNextBestThousandDollars: NextBestDollar[];
}

const SPEND_AND_EFFECT_SORT_OPTIONS = [
  { value: "Alphabetical" },
  { value: "Spend to effect ratio" },
  { value: "Spend: High to low" },
  { value: "Spend: Low to high" },
  { value: "Effect: High to low" },
  { value: "Effect: Low to high" },
] as const;

const InvestToIncrease: React.FC<InvestToIncreaseProps> = ({
  groupByMetric,
  groupByLevel,
  unGroupedSpendAndEffect,
  unGroupedNextBestThousandDollars,
}) => {
  const [spendAndEffectSorted, setSpendAndEffectSorted] = useState<any[]>([]);
  const [nextBestThousandDollarsSorted, setNextBestThousandDollarsSorted] = useState<any[]>([]);
  const [snapChartSortValueSpendEffect, setSnapChartSortValueSpendEffect] = useState(
    "Spend: High to low"
  );
  const [snapChartSortValueNextBest, setSnapChartSortValueNextBest] = useState("Highest to lowest");

  const spendAndEffect = useMemo(() => {
    if (unGroupedSpendAndEffect.length !== 0) {
      let finalSpendAndEffect: any[] = [];
      switch (groupByLevel) {
        case "channel":
          const groupedSumsChannel = unGroupedSpendAndEffect.reduce((acc, entry) => {
            if (entry.name !== "Covariates") {
              if (!acc[entry.channel]) {
                acc[entry.channel] = { effectivePredictedResponse: 0, spendShare: 0 };
              }
              acc[entry.channel].effectivePredictedResponse +=
                entry.effectivePredictedResponse || 0;
              acc[entry.channel].spendShare += entry.spendShare || 0;
            }
            return acc;
          }, {});

          const totalSumsChannel = unGroupedSpendAndEffect.reduce(
            (acc, entry) => {
              if (entry.name !== "Covariates") {
                acc.totalEffective += entry.effectivePredictedResponse || 0;
                acc.totalSpend += entry.spendShare || 0;
              }
              return acc;
            },
            { totalEffective: 0, totalSpend: 0 }
          );

          finalSpendAndEffect = Object.entries(groupedSumsChannel).map(([channel, data]) => {
            const typedData = data as { effectivePredictedResponse: number; spendShare: number };
            return {
              label: "Spend",
              label2: "Effect",
              name: channel,
              value: typedData.spendShare / totalSumsChannel.totalSpend,
              value2: typedData.effectivePredictedResponse / totalSumsChannel.totalEffective,
            };
          });
          break;
        default:
          const groupedSums = unGroupedSpendAndEffect.reduce((acc, entry) => {
            if (entry.name !== "Covariates") {
              const key = keyGeneratorForChannelTacticBrandGrouping(
                entry.channel,
                entry.tactic,
                entry["brand/nonbrand"]
              );

              if (!acc[key]) {
                acc[key] = { effectivePredictedResponse: 0, spendShare: 0 };
              }

              acc[key].effectivePredictedResponse += entry.effectivePredictedResponse || 0;
              acc[key].spendShare += entry.spendShare || 0;
            }
            return acc;
          }, {});

          const totalSums = unGroupedSpendAndEffect.reduce(
            (acc, entry) => {
              if (entry.name !== "Covariates") {
                acc.totalEffective += entry.effectivePredictedResponse || 0;
                acc.totalSpend += entry.spendShare || 0;
              }
              return acc;
            },
            { totalEffective: 0, totalSpend: 0 }
          );
          finalSpendAndEffect = Object.entries(groupedSums).map(([key, data]) => {
            const [channel, tactic, brand] = key.split(",");
            const typedData = data as { effectivePredictedResponse: number; spendShare: number };
            return {
              label: "Spend",
              label2: "Effect",
              name: keyGeneratorForChannelTacticBrandGrouping(channel, tactic, brand),
              value: typedData.spendShare / totalSums.totalSpend,
              value2: typedData.effectivePredictedResponse / totalSums.totalEffective,
            };
          });
      }
      return finalSpendAndEffect;
    }
    return [];
  }, [groupByLevel, unGroupedSpendAndEffect]);

  const nextBestThousandDollars = useMemo(() => {
    if (unGroupedNextBestThousandDollars.length !== 0) {
      let finalNextBestThousandDollars;
      switch (groupByLevel) {
        case "channel":
          finalNextBestThousandDollars = Object.values(
            unGroupedNextBestThousandDollars.reduce<
              Record<string, { value: number; channel: string }>
            >((acc, entry) => {
              if (!acc[entry.channel]) {
                acc[entry.channel] = { value: entry.value || 0, channel: entry.channel };
              } else {
                acc[entry.channel].value += entry.value || 0;
              }
              return acc;
            }, {})
          ).map(data => ({
            name: data.channel,
            label: "Spend",
            value: data.value,
          }));
          break;

        default:
          finalNextBestThousandDollars = Object.values(
            unGroupedNextBestThousandDollars.reduce<Record<string, { value: number; key: string }>>(
              (acc, entry) => {
                const key = keyGeneratorForChannelTacticBrandGrouping(
                  entry.channel,
                  entry.tactic,
                  entry["brand/nonbrand"]
                );

                if (!acc[key]) {
                  acc[key] = { value: entry.value || 0, key };
                } else {
                  acc[key].value += entry.value || 0;
                }
                return acc;
              },
              {}
            )
          ).map(data => ({
            name: data.key,
            label: "Spend",
            value: data.value,
          }));
          break;
      }
      return finalNextBestThousandDollars;
    }
    return [];
  }, [groupByLevel, unGroupedNextBestThousandDollars]);

  useEffect(() => {
    if (!R.isEmpty(nextBestThousandDollars)) {
      const sortedNextBestData = snapShotDataSort(
        nextBestThousandDollars,
        snapChartSortValueNextBest
      );
      const formattedAndSortedNextBestDollar = sortedNextBestData.map(item => {
        const formattedName = item.name
          .split("_")
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        return {
          ...item,
          name: formattedName,
        };
      });
      setNextBestThousandDollarsSorted(formattedAndSortedNextBestDollar);
    }
  }, [nextBestThousandDollars, snapChartSortValueNextBest]);

  useEffect(() => {
    if (!R.isEmpty(spendAndEffect)) {
      const sortedSpendAndEffect = snapShotDataSort(spendAndEffect, snapChartSortValueSpendEffect);
      const formattedAndSortedSpendAndEffect = sortedSpendAndEffect.map(item => {
        const formattedName = item.name
          .split("_")
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        return {
          ...item,
          name: formattedName,
        };
      });
      setSpendAndEffectSorted(formattedAndSortedSpendAndEffect);
    }
  }, [spendAndEffect, snapChartSortValueSpendEffect]);

  return (
    <WidgetContainer
      collapsible
      header={`Which channels should we invest in to increase ${standardizeMetricName(
        groupByMetric
      )}?`}
      subHeader={
        <>
          {
            "Below is the marginal impact of each media dollar, given the existing allocation of resources."
          }
          <MoreInfo rightLabel="More info" size="sm">
            {
              "The marginal impact of a media dollar is based on where one is on the channel saturation curve (see section below). Next-dollar effectiveness is the slope of the line tangent to the saturation curve at the current level of investment. Intuitively, you want to invest where the slope of the curve is steepest."
            }
          </MoreInfo>
        </>
      }
    >
      <div className="investMain">
        <div className="investLeft">
          <SnapshotChart
            data={spendAndEffectSorted}
            dropdownOptions={SPEND_AND_EFFECT_SORT_OPTIONS}
            dropdownValue={snapChartSortValueSpendEffect}
            setDropdownValue={setSnapChartSortValueSpendEffect}
            doubleBarChart={true}
            header={undefined}
            title={"Spend and Effect"}
            valueFormatter={value => (value === null ? "--" : `${(value * 100).toFixed(0)}%`)}
          />
        </div>
        <div className="investRight">
          <SnapshotChart
            title={"Next Best Dollar"}
            data={nextBestThousandDollarsSorted}
            dropdownOptions={[
              { value: "Alphabetical" },
              { value: "Highest to lowest" },
              { value: "Lowest to highest" },
            ]}
            dropdownValue={snapChartSortValueNextBest}
            setDropdownValue={setSnapChartSortValueNextBest}
            doubleBarChart={false}
            header={`The most efficient channel investment strategy to raise ${groupByMetric} by 1% point. Channels allocated larger investments drive the most dollar for dollar incremental impact.`}
            valueFormatter={value => (value === null ? "--" : currencyFormatter.format(value))}
          />
        </div>
      </div>
    </WidgetContainer>
  );
};

export default InvestToIncrease;
