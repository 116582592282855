import React, { Dispatch, SetStateAction, useMemo } from "react";
import cn from "classnames";
import * as R from "ramda";
import { KnowYourCustomerData } from "@blisspointmedia/bpm-types/dist/KnowYourCustomer";
import WidgetContainer from "../Components/WidgetContainer";
import { BPMTable, Header } from "../Components";
import { formatDecimal, formatNumber, formatPercent } from "../utils/format-utils";
import {
  CUSTOMER_INSIGHTS_LEVELS_PRETTY_NAMES,
  CUSTOMER_INSIGHTS_PRETTY_NAMES,
  CustomerInsightsFields,
  SortOptions,
  TabKey,
  ViewOptions,
} from "./customerInsightsConstants";
import { getCustomerInsightsAlphabeticalSort } from "./customerInsightsUtils";
import CustomerBaseChart from "./CustomerBaseChart";
import IndexChart from "./IndexChart";
import PenetrationChart from "./PenetrationChart";

const CustomerKey = () => (
  <>
    <div className="keyFilled customerKey" />
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M9 0C10.1819 1.4094e-08 11.3522 0.232792 12.4442 0.685084C13.5361 1.13738 14.5282 1.80031 15.364 2.63604C16.1997 3.47177 16.8626 4.46392 17.3149 5.55585C17.7672 6.64778 18 7.8181 18 9L9 9V0Z"
        fill="#CBD2E1"
      />
      ``
    </svg>
    <span>Customer</span>
  </>
);

const USMarketKey = () => (
  <>
    <div className="keyFilled marketKey" />
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <circle cx="9" cy="9" r="9" fill="#E1E6EF" />
      <path
        d="M9 0C10.1819 1.4094e-08 11.3522 0.232792 12.4442 0.685084C13.5361 1.13738 14.5282 1.80031 15.364 2.63604C16.1997 3.47177 16.8626 4.46392 17.3149 5.55585C17.7672 6.64778 18 7.8181 18 9L9 9V0Z"
        fill="#CBD2E1"
      />
    </svg>
    <div>US Market</div>
  </>
);

interface CustomerInsightsGroupProps {
  fields: string[];
  data: Record<string, KnowYourCustomerData[]>;
  header: string;
  sortValue: SortOptions;
  graphView: ViewOptions;
  tab: TabKey;
  companyName: string;
  suppressUnknown: boolean;
}

const CustomerInsightsGroup: React.FC<CustomerInsightsGroupProps> = ({
  fields,
  data,
  header,
  sortValue,
  graphView,
  tab,
  companyName,
  suppressUnknown,
}) => {
  const sortedData = useMemo(() => {
    const dataToSort = R.clone(data);

    if (sortValue === "alphabetical") {
      Object.keys(dataToSort).forEach(key => {
        const value = dataToSort[key];
        const fieldKey = key as CustomerInsightsFields;
        dataToSort[key] = getCustomerInsightsAlphabeticalSort(fieldKey, value);
      });
      return dataToSort;
    }

    let getProperty: (data: KnowYourCustomerData) => any;
    switch (tab) {
      case TabKey.CUSTOMER_BASE:
        getProperty = R.prop("clientPopulation");
        break;
      case TabKey.INDEX:
        getProperty = ({ clientPopulation, tuPopulation }) => clientPopulation / tuPopulation;
        break;
      case TabKey.PENETRATION:
        getProperty = ({ clientPopulationFraction, tuPopulationFraction }) =>
          clientPopulationFraction / tuPopulationFraction;
        break;
    }

    let by: (a: KnowYourCustomerData, b: KnowYourCustomerData) => number;
    switch (sortValue) {
      case "high to low":
        by = R.descend(getProperty);
        break;
      case "low to high":
        by = R.ascend(getProperty);
        break;
    }

    // Apply numerical sort
    Object.keys(dataToSort).forEach(fieldName => {
      const values = dataToSort[fieldName];
      dataToSort[fieldName] = R.sort(by)(values);

      // Replace original levels with pretty names
      Object.keys(CUSTOMER_INSIGHTS_LEVELS_PRETTY_NAMES[fieldName]).forEach(dataName => {
        const prettyName = CUSTOMER_INSIGHTS_LEVELS_PRETTY_NAMES[fieldName][dataName];
        const valsIndex = dataToSort[fieldName].findIndex(item => item.levels === dataName);
        if (valsIndex !== -1) {
          dataToSort[fieldName][valsIndex].levels = prettyName;
        }
      });
    });

    return dataToSort;
  }, [data, sortValue, tab]);

  const renderGraph: (field: string) => React.ReactNode = useMemo(() => {
    return field => {
      const fieldName = field as CustomerInsightsFields;
      switch (tab) {
        case TabKey.CUSTOMER_BASE:
          return <CustomerBaseChart fieldName={fieldName} data={sortedData[field]} />;
        case TabKey.INDEX:
          return <IndexChart fieldName={fieldName} data={sortedData[field]} />;
        case TabKey.PENETRATION:
          return <PenetrationChart fieldName={fieldName} data={sortedData[field]} />;
      }
    };
  }, [sortedData, tab]);

  const renderTable: (field: string) => React.ReactNode = useMemo(() => {
    return field => {
      const fieldPrettyName = CUSTOMER_INSIGHTS_PRETTY_NAMES[field];
      const tableHeaders: Header[] = [
        {
          name: "levels",
          label: "",
          flex: true,
          nonInteractive: true,
          renderer: ({ levels }) => {
            return <span className="tableLevels">{levels}</span>;
          },
        },
      ];

      switch (tab) {
        case TabKey.CUSTOMER_BASE:
          tableHeaders.push(
            {
              flex: true,
              nonInteractive: true,
              name: "clientPopulation",
              label: `${companyName}'s Population`,
              renderer: (data: KnowYourCustomerData) =>
                formatNumber(Math.round(data.clientPopulation)),
            },
            {
              flex: true,
              nonInteractive: true,
              name: "clientPopulationFraction",
              label: "Distribution",
              renderer: (data: KnowYourCustomerData) =>
                formatPercent(data.clientPopulationFraction, 2),
            }
          );
          break;
        case TabKey.INDEX:
          tableHeaders.push(
            {
              flex: true,
              nonInteractive: true,
              name: "index",
              label: "Index",
              renderer: (data: KnowYourCustomerData) =>
                formatDecimal(data.clientPopulationFraction / data.tuPopulationFraction, 2),
            },
            {
              flex: true,
              nonInteractive: true,
              name: "clientPopulationFraction",
              label: `${companyName}'s Distribution`,
              renderer: (data: KnowYourCustomerData) =>
                formatPercent(data.clientPopulationFraction, 2),
            },
            {
              flex: true,
              nonInteractive: true,
              name: "tuPopulationFraction",
              label: "US Market Distribution",
              renderer: (data: KnowYourCustomerData) => formatPercent(data.tuPopulationFraction, 2),
            }
          );
          break;
        case TabKey.PENETRATION:
          tableHeaders.push(
            {
              flex: true,
              nonInteractive: true,
              name: "penetration",
              label: "Penetration",
              renderer: (data: KnowYourCustomerData) =>
                formatPercent(data.clientPopulation / data.tuPopulation, 1),
            },
            {
              flex: true,
              nonInteractive: true,
              name: "clientPopulation",
              label: `${companyName}'s Population`,
              renderer: (data: KnowYourCustomerData) =>
                formatNumber(Math.round(data.clientPopulation)),
            },
            {
              flex: true,
              nonInteractive: true,
              name: "tuPopulation",
              label: "US Market Population",
              renderer: (data: KnowYourCustomerData) => formatNumber(Math.round(data.tuPopulation)),
            }
          );
          break;
      }

      return (
        <div className="tableContainer">
          <div className="title">{fieldPrettyName}</div>
          <BPMTable data={sortedData[field]} headers={tableHeaders} filterBar={false} />
        </div>
      );
    };
  }, [companyName, sortedData, tab]);

  return (
    <WidgetContainer
      header={header}
      rightActions={
        [TabKey.INDEX, TabKey.PENETRATION].includes(tab) ? (
          <div className="customerInsightsGroupActions">
            <div className="customerInsightsKey">
              <span className="customerInsightsKeyItem">
                <CustomerKey />
              </span>
              <span className="customerInsightsKeyItem">
                <USMarketKey />
              </span>
            </div>
          </div>
        ) : undefined
      }
      collapsible
    >
      {fields.map(field => (
        <div
          key={field}
          className={cn("customerInsightsGroup", {
            fullWidth: field === CustomerInsightsFields.INCOME,
            incomeUnknownHeight: field === CustomerInsightsFields.INCOME && !suppressUnknown,
            incomeNoUnknownHeight: field === CustomerInsightsFields.INCOME && suppressUnknown,
            fixedHeight: field !== CustomerInsightsFields.INCOME,
          })}
        >
          {graphView === "showGraph" && renderGraph(field)}
          {graphView === "showTable" && renderTable(field)}
        </div>
      ))}
    </WidgetContainer>
  );
};

interface CustomerInsightsLegendProps {
  data: {
    name: string;
    val1: string;
    val2?: string;
    computedValue: string;
    color?: string;
  }[];
  hoveredValue?: string;
  setHoveredValue?: Dispatch<SetStateAction<string | undefined>>;
  selectedValue?: string;
  setSelectedValue?: Dispatch<SetStateAction<string | undefined>>;
}

export const CustomerInsightsLegend: React.FC<CustomerInsightsLegendProps> = ({
  data,
  hoveredValue,
  setHoveredValue,
  selectedValue,
  setSelectedValue,
}) => (
  <div className="legend">
    {data.map(({ name, val1, val2, computedValue, color }) => (
      <div
        key={name}
        className={cn("legendItem", {
          hoveredValue: name === hoveredValue,
          unhoveredValue: Boolean(hoveredValue) && name !== hoveredValue,
          selectedValue: !Boolean(hoveredValue) && name === selectedValue,
          unselectedValue:
            !Boolean(hoveredValue) && Boolean(selectedValue) && name !== selectedValue,
        })}
        onMouseEnter={setHoveredValue ? () => setHoveredValue(name) : undefined}
        onMouseLeave={setHoveredValue ? () => setHoveredValue(undefined) : undefined}
        onClick={
          setSelectedValue
            ? () => setSelectedValue(name === selectedValue ? undefined : name)
            : undefined
        }
      >
        {color && <div className="itemColor" style={{ backgroundColor: color }} />}
        <div className="itemLabel">
          <div>{name}</div>
        </div>
        <div className="itemComputedValue">{computedValue}</div>
        <div className="stackedItemValues">
          {val1 && <div className="itemValue">{val1}</div>}
          {val2 && <div className="itemValue">{val2}</div>}
        </div>
      </div>
    ))}
  </div>
);

export default CustomerInsightsGroup;
